import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { getHeaders } from '../../Api/ClientUtils';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Header from '../../Molecules/Header/Header';
import Footer from '../../Molecules/Footer/Footer';
import Button from '../../Atoms/Button/Button';
import strings from '../../../lang';
import Modal from '../../Molecules/Modal/Modal';
import LogoutMessage from '../../Molecules/Toaster/LogoutMessage';

class AdministratorList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            posts: [],
            errorMsg: '',
            sucMsg: '',
            pending: true,
            logouttoast: false,
        }
        this.listAdmins()
    }
    listAdmins(props) {
        axios.get('list-superuser', { headers: getHeaders(this.props.token) })
            .then(response => {
                this.setState({ posts: response.data.loginUserList, pending: false })
            })
            .catch(async error => {
                this.setState({
                    errorMsg: 'Error while listing admin users.',
                    posts: '', pending: false
                })
                // window.setTimeout(() => { this.setState({ errorMsg: '' }) }, 5000)
                if (error.response.data.statusCode === 401) {
                    this.setState({ logouttoast: true })
                    await this.sleep(5000);
                    this.props.history.push("/logout");
                }
            })
    }
    deleteHandler(props) {
        const userId = this.state.DeleteID.toString()
        axios.post('delete-superuser', { userId }, { headers: getHeaders(this.props.token) })
            .then(response => {
                document.getElementById(`deleteModal`).style.display = 'none';
                this.setState({ sucMsg: 'Successfully deleted Admin user.' })
                // window.setTimeout(() => { this.setState({ sucMsg: '' }) }, 5000)
                this.listAdmins()
            })
            .catch(async error => {
                if (error.response.data.statusCode === 401) {
                    this.setState({ logouttoast: true })
                    await this.sleep(5000);
                    this.props.history.push("/logout");
                }
                this.setState({ errorMsg: 'Error while deleting the admin user.' })
                // + error.response.data.message
                // window.setTimeout(() => { this.setState({ errorMsg: '' }) }, 5000)
            })
    }
    adminEnableDisable(user_Id,user_status){
        const userId = user_Id.toString()
        const userStatus = user_status
        axios.post('update-status-superuser', { userId, userStatus }, { headers: getHeaders(this.props.token) })
            .then(response => {
                this.setState({ sucMsg: 'Admin user status changed successfully.' })
                // window.setTimeout(() => { this.setState({ sucMsg: '' }) }, 5000)
                this.listAdmins()
            })
            .catch(async error => {
                if (error.response.data.statusCode === 401) {
                    this.setState({ logouttoast: true })
                    await this.sleep(5000);
                    this.props.history.push("/logout");
                }
                this.setState({ errorMsg: 'Error while changing the user status' })
                // window.setTimeout(() => { this.setState({ errorMsg: '' }) }, 5000)
            })

    }
    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    render() {
        const { posts, errorMsg, sucMsg, pending } = this.state
        const columns = [
            {
                name: 'Name',
                selector: 'adminName',
                sortable: true,

            },
            {
                name: 'User ID',
                selector: 'username',
                sortable: true,

            },
            {
                name: 'Status',
                selector: 'status',
                sortable: true,
                width: '120px'
            },
            {
                name: 'Edit',
                selector: 'id',
                cell: row => <Link to={"/admin-add/" + row.userId}><Button>Setup</Button></Link>,
                ignoreRowClick: true,
                allowOverflow: true,
                width: '100px'
            },
            {
                name: 'Enable/Disable',
                selector: 'id',
            cell: (row) => <div>{row.status === 'ACTIVE' && this.props.username !== row.username &&<div onClick={(e) => {
                e.stopPropagation();
                this.adminEnableDisable(row.userId, 0)
            }} ><Button>Disable</Button></div>}
            {row.status === 'INACTIVE' && this.props.username !== row.username &&<div onClick={(e) => {
                e.stopPropagation();
                this.adminEnableDisable(row.userId, 1)
            }} ><Button>Enable</Button></div>}</div>,    
            ignoreRowClick: true,
                allowOverflow: true,
                width: '120px'
            },
            {
                name: 'Delete',
                selector: 'id',
                cell: (row) => <div
                    className="center"
                    onClick={e => {
                        e.stopPropagation();
                        document.getElementById(`deleteModal`).style.display = 'block';
                        this.setState({ DeleteID: row.userId })
                    }}
                    role="presentation"
                >
                    {this.props.username !== row.username && <img
                        src={require('../../../assets/images/delete.svg')}
                        alt={strings.delete}
                        className="delete-img"
                    />}
                </div>,
                ignoreRowClick: true,
                allowOverflow: true,
                width: '120px'
            }
        ];
        return (
            <div id="container">
                <Header />
                <LogoutMessage logoutToast={this.state.logouttoast} />
                <Modal
                    headerText={strings.confirmDelete}
                    uniqueModalName="deleteModal"
                    message={strings.requestDeleteAdminConfirmMessage}
                    minusText={strings.cancel}
                    minusAction={() => {
                        document.getElementById('deleteModal').style.display = 'none';
                    }}
                    plusText={strings.confirm}
                    plusAction={() => {
                        this.deleteHandler(this.state.DeleteID);
                    }}
                />
                <div className="container topmargin">
                    <div className="row">
                        <div className="col-md-10"><h4>View Admins</h4></div>
                        <div className="col-md-2"> <Link to={"/admin-add/"}><Button>New Admin</Button></Link></div>
                    </div>
                    {errorMsg !== '' && <div className="erroralert" >{errorMsg}</div>}
                    {sucMsg !== '' && <div className="successtext">{sucMsg}</div>}
                    <DataTable
                        columns={columns}
                        data={posts}
                        pagination={true}
                        highlightOnHover={true}
                        dense={true}
                        responsive={true}
                        progressPending={pending}
                        noHeader={true}
                    />
                </div>
                <Footer />
            </div>
        );
    }
}

const mapstateToProps = state => {
    return {
        token: state.myLogin.token,
        username: state.myLogin.username
    }
}
export default connect(
    mapstateToProps)
    (AdministratorList)
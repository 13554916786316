const lang = {
  save: 'Save',
  name: 'Name',
  salary: 'Salary',
  age: 'Age',
  logo: 'Logo',
  bloodBank: 'Blood Bank',
  request: 'Request',
  tabOpen: 'Open',
  tabMyRequest: 'My Request',
  tabMyOffers: 'My Offers',
  tabSettings: 'Settings',
  addRequest: 'Add Request',
  view: 'View',
  hideView: 'Hide View',
  more: 'More',
  call: 'Call',
  delete: 'Delete',
  group: 'Group',
  place: 'Place',
  time: 'Time',
  contact: 'Contact',
  operations: 'Operations',
  confirmDelete: 'Confirm Delete',
  requestDeleteConfirmMessage: 'Do you want to delete this tenant?',
  requestDeleteAppConfirmMessage: 'Do you want to delete this tenant application?',
  requestDeleteUserConfirmMessage:'Do you really want to delete this tenant user?',
  requestDeleteAdminConfirmMessage:'Do you really want to delete this admin user?',
  requestDeleteMemberConfirmMessage:'Do you really want to delete this user?',
  cancel: 'Cancel',
  confirm: 'Confirm',
  refresh: 'Refresh'
};

export default lang;

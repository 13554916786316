import {
  SAVE_APP_ID
  } from '../../Constants/Type';
  
  const INITIAL_STATE = {
    saveOperaAppId: ''
  };
  const AppId = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case SAVE_APP_ID:
        return {
          ...state,
          saveOperaAppId: action.payload
        };              
        default:
          return state;
    }
  };
  
  export default AppId;
  
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Header from '../../Molecules/Header/Header';
import Footer from '../../Molecules/Footer/Footer';
import Button from '../../Atoms/Button/Button';
import axios from 'axios';
import { getHeaders } from '../../Api/ClientUtils';
// import API from '../../Api/ApiPath';
import strings from '../../../lang';
import Modal from '../../Molecules/Modal/Modal';
import './TenantList.css';
import LogoutMessage from '../../Molecules/Toaster/LogoutMessage';
class TenantList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            posts: [],
            errorMsg: '',            
            DeleteID: '',
            sucMsg: '',
            pending: true,
            logouttoast: false,
        }
        this.deleteHandler = this.deleteHandler.bind(this)
    }
    componentDidMount() {
        this.listTenants()
    }
    listTenants() {
        axios.get('list-tenant', { headers: getHeaders(this.props.token) })        
        // API.get('list-tenant')
            .then(response => {
                this.setState({ posts: response.data.tenantList })
                this.setState({ pending: false })
            })
            .catch(async error => {
                if (error.response.data.statusCode === 401) {
                    this.setState({ logouttoast: true })
                    await this.sleep(5000);
                    this.props.history.push("/logout");
                }
                // this.setState({ errorMsg: 'Error - ' + error.response.data.message })
                // window.setTimeout(()=>{this.setState({errorMsg:''})},5000)
                this.setState({ pending: false })
            })
    }
    deleteHandler() {
        const tenantId = this.state.DeleteID.toString()
        axios.post('delete-tenant',{ tenantId }, { headers: getHeaders(this.props.token) })  
        // API.post('delete-tenant', { tenantId })
            .then(response => {
                document.getElementById(`deleteModal`).style.display = 'none';
                this.setState({ sucMsg: 'Successfully soft deleted the tenant, You can enable tenant, later if required.' })
                // window.setTimeout(()=>{this.setState({sucMsg:''})},5000)
                this.listTenants()
            })
            .catch(async error => {
                if (error.response.data.statusCode === 401) {
                    this.setState({ logouttoast: true })
                    await this.sleep(5000);
                    this.props.history.push("/logout");
                }
                this.setState({ errorMsg: 'Error - ' + error.response.data.message })
                // window.setTimeout(()=>{this.setState({errorMsg:''})},5000)
            })
    }
    tenantEnableDisable(tenant_Id,tenant_status){
        const tenantId = tenant_Id.toString()
        const tenantStatus = tenant_status
        axios.post('update-status-tenant',{ tenantId, tenantStatus }, { headers: getHeaders(this.props.token) }) 
        // API.post('update-status-tenant', { tenantId, tenantStatus })
            .then(response => {
                this.setState({ sucMsg: 'Tenant status changed successfully.' })
                // window.setTimeout(() => { this.setState({ sucMsg: '' }) }, 5000)
                this.listTenants()
            })
            .catch(async error => {
                console.log(error)
                if (error.response.data.statusCode === 401) {
                    this.setState({ logouttoast: true })
                    await this.sleep(5000);
                    this.props.history.push("/logout");
                }
                this.setState({ errorMsg: 'Error - ' + error.response.data.message })
                // window.setTimeout(() => { this.setState({ errorMsg: '' }) }, 5000)
            })

    }
    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    render() {
        const { posts, errorMsg, sucMsg, pending } = this.state
        const columns = [
            {
                name: 'Name',
                selector: 'tenantName',
                sortable: true,
                // width: '200px'
            },
            {
                name: 'Admin',
                selector: 'userEmail',
                sortable: true,
                width: '300px'
            },
            {
                name: 'Status',
                selector: 'tenantStatus',
                sortable: true,
                // width: '100px'
            },
            {
                name: 'Manage',
                selector: 'id',
                cell: row => <Link to={"/tenant-add/" + row.tenantId}><Button>Manage</Button></Link>,
                ignoreRowClick: true,
                allowOverflow: true,
                // width: '120px'
            },
            // {
            //     name: 'Users',
            //     selector: 'id',
            //     cell: row => <Link to={"/tenant-user-list/" + row.tenantId}><Button>Users</Button></Link>,
            //     ignoreRowClick: true,
            //     allowOverflow: true,
            //     width: '120px'
            // },
            // {
            //     name: 'Applications',
            //     selector: 'id',
            //     cell: row => <Link to={"/application-list/" + row.tenantId}><Button>Applications</Button></Link>,
            //     ignoreRowClick: true,
            //     allowOverflow: true,
            //     width: '120px'
            // },
            {
                name: 'Enable/Disable',
                selector: 'tenantId',
            cell: (row) => <div>{row.tenantStatus === 'ACTIVE' &&<div onClick={(e) => {
                e.stopPropagation();
                this.tenantEnableDisable(row.tenantId, 0)
            }} ><Button>Disable</Button></div>}
            {row.tenantStatus === 'INACTIVE' &&<div onClick={(e) => {
                e.stopPropagation();
                this.tenantEnableDisable(row.tenantId, 1)
            }} ><Button>Enable</Button></div>}</div>,    
            ignoreRowClick: true,
                allowOverflow: true,
                // width: '120px'
            },
            {
                name: 'Delete',
                selector: 'id',
                cell: (row) => <div
                    className="center"
                    onClick={e => {
                        e.stopPropagation();
                        document.getElementById(`deleteModal`).style.display = 'block';
                        this.setState({ DeleteID: row.tenantId })

                    }}
                    role="presentation"
                >
                    <img
                        src={require('../../../assets/images/delete.svg')}
                        alt={strings.delete}
                        className="delete-img"
                    />
                </div>,
                ignoreRowClick: true,
                allowOverflow: true,
                // width: '100px'
            }
        ];
        return (
            <div id="container">
                <Header />
                <LogoutMessage logoutToast={this.state.logouttoast} />
                <Modal
                    headerText={strings.confirmDelete}
                    uniqueModalName="deleteModal"
                    message={strings.requestDeleteConfirmMessage}
                    minusText={strings.cancel}
                    minusAction={() => {
                        document.getElementById('deleteModal').style.display = 'none';
                    }}
                    plusText={strings.confirm}
                    plusAction={() => {
                        this.deleteHandler(this.state.DeleteID);
                    }}
                />
                <div className="container topmargin">                    
                    <div className="row">
                        <div className="col-md-10"><h4>View Tenants</h4></div>
                        <div className="col-md-2"> <Link to={"/tenant-add/"}><Button>New Tenant</Button></Link></div>
                    </div>
                    {errorMsg !=='' &&<div className="erroralert" >{errorMsg}</div>}
                    {sucMsg !=='' &&<div className="successtext">{sucMsg}</div>}
                    <DataTable
                        columns={columns}
                        data={posts}
                        pagination={true}
                        highlightOnHover={true}
                        dense={true}
                        responsive={true}
                        progressPending={pending}
                        noHeader={true}
                    />
                </div>
                <Footer />
            </div>
        );
    }
}
const mapstateToProps = state => {
    return {
        token: state.myLogin.token
    }
}
export default connect(
    mapstateToProps)
    (TenantList);
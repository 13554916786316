import React from 'react';
import { connect } from 'react-redux';
import Header from '../../Molecules/Header/Header';
import Footer from '../../Molecules/Footer/Footer';
import './TenantAdd.css';
import axios from 'axios';
import { getHeaders } from '../../Api/ClientUtils';
// import API from '../../Api/ApiPath';
import LogoutMessage from '../../Molecules/Toaster/LogoutMessage';
import LoadingSpinner from '../../Molecules/LoadingSpinner/LoadingSpinner';
import Leftsidebar from '../../Molecules/Leftsidebar/Leftsidebar';
import Spinner from 'react-bootstrap/Spinner';
class TenantAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tenantName: '',
            domain: '',
            url: '',
            tenantEmail: '',
            contactNo: '',
            errorMsg: '',
            sucMsg: '',
            tenantId: '',
            posts: [],
            tenantUserList: [],
            logouttoast: false,
            loading: false,
            connecting: false,
        }
    }
    validate = () => {
        let isError = false;
        const errors = {
            tenantnameError: "",
            domainError: "",
            urlError: "",
            tenantEmailError:"",
            errorMsg: '',
            sucMsg: '',
        };
        if (this.state.tenantName === '') {
            isError = true;
            errors.tenantnameError = "Tenant name required";
        }
        if (this.state.domain === '') {
            isError = true;
            errors.domainError = "Domain required";
        }
        // if (this.state.url === '') {
        //     isError = true;
        //     errors.urlError = "URL required";
        // }
        if (this.state.tenantEmail === '') {
            isError = true;
            errors.tenantEmailError = "Admin User ID is required";
        }
        var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (this.state.tenantEmail === '' || !(this.state.tenantEmail.match(mailformat))) {
            isError = true;
            errors.tenantEmailError = "Admin User ID is not a valid email address";
        }        
        this.setState({
            ...this.state,
            ...errors
        });
        return isError;
    };
    changeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    submitHandler = (e) => {
        e.preventDefault()
        const err = this.validate();
        if (!err) {
            this.setState({
                tenantnameError: "",
                domainError: "",
                urlError: "",
                tenantEmailError:""
            });
            const { tenantId } = this.props.match.params
            if (tenantId !== '' && tenantId !== undefined) {
                this.setState({ connecting: true }, () => {
                    axios.post('update-tenant', this.state, { headers: getHeaders(this.props.token) })  
                    // API.post('update-tenant', this.state)
                        .then(response => {
                            // this.props.history.push("/tenant-list");
                            this.setState({ sucMsg: 'Tenant updated successfully.', connecting: false })
                            // window.setTimeout(() => { this.setState({ sucMsg: '' }) }, 5000)
                        })
                        .catch(async error => {
                            if (error.response.data.statusCode === 401) {
                                this.setState({ logouttoast: true })
                                await this.sleep(5000);
                                this.props.history.push("/logout");
                            }
                            this.setState({ errorMsg: 'Error - ' + error.response.data.message, connecting: false })
                            // window.setTimeout(() => { this.setState({ errorMsg: '' }) }, 5000)
                        })
                });
            } else {
                this.setState({ connecting: true }, () => {
                    axios.post('add-tenant', this.state, { headers: getHeaders(this.props.token) })  
                    // API.post('add-tenant', this.state)
                        .then(response => {
                            this.props.history.push("/tenant-list");
                        })
                        .catch(async error => {
                            if (error.response.data.statusCode === 401) {
                                this.setState({ logouttoast: true })
                                await this.sleep(5000);
                                this.props.history.push("/logout");
                            }
                            this.setState({ errorMsg: 'Error - ' + error.response.data.message, connecting: false })
                            // window.setTimeout(() => { this.setState({ errorMsg: '' }) }, 5000)
                        })
                });
            }
        }
    }
    ddchangeHandler(event) {
        this.setState({ tenantEmail: event.target.value });
    }
    componentDidMount(_props) {
        const { tenantId } = this.props.match.params
        var href = window.location.href;
        var pathname = window.location.pathname
        var baseurl = href.replace(pathname, '') + '/admin-login';
        this.setState({ tenantId: tenantId, baseurl: baseurl, createdBy: this.props.createdBy  })
        if (tenantId !== '' && tenantId !== undefined) {
            this.setState({ loading: true }, () => {
                axios.post('view-tenant', { tenantId }, { headers: getHeaders(this.props.token) })  
                // API.post('view-tenant', { tenantId })
                    .then(response => {
                        this.setState({
                            tenantName: response.data.tenant.tenantName,
                            domain: response.data.tenant.domain,
                            url: response.data.tenant.url,
                            contactNo: response.data.tenant.tenantContactNo,
                            tenantUserList: response.data.tenant.tenantUserList,
                            tenantEmail: response.data.tenant.userEmail,
                            loading: false
                        })
                    })
                    .catch(async error => {
                        if (error.response.data.statusCode === 401) {
                            this.setState({ logouttoast: true })
                            await this.sleep(5000);
                            this.props.history.push("/logout");
                        }
                        this.setState({ errorMsg: 'Error - ' + error.response.data.message, loading: false })
                        // window.setTimeout(() => { this.setState({ errorMsg: '' }) }, 5000)
                    })
            });
        }
    }
    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    render() {
        const { tenantId, errorMsg, sucMsg, loading, connecting } = this.state
        let pageTitle, buttonTitle, Readonlyflag, showtext, showdropdown, buttonTitles
        if (tenantId !== '' && tenantId !== undefined) {
            pageTitle = 'Manage Tenant Profile';
            buttonTitle = 'Update';
            buttonTitles = 'Updating...';
            Readonlyflag = true;
            showtext = false;
            showdropdown = true;
        } else {
            pageTitle = 'Tenant Setup';
            buttonTitle = 'Create';
            buttonTitles = 'Creating...';
            Readonlyflag = false;
            showtext = true;
            showdropdown = false;
        }
        let tenantUserLists = this.state.tenantUserList;
        let optionItems = tenantUserLists.map((tenantUserList) =>
            <option key={tenantUserList.userId} value={tenantUserList.email}>{tenantUserList.email}</option>
        );
        return (
            <div id="container">
                <Header />
                <div id="wraps">
                    {Readonlyflag && <Leftsidebar tenantId={tenantId} />}
                    <div id="contents">
                        <LogoutMessage logoutToast={this.state.logouttoast} />
                        <div className="container topmargin" >
                            {/* {Readonlyflag &&<div className="row InnerNavbar">
                        <div className="col-md-12">
                            <Breadcrumbs tenantId={tenantId} />
                        </div>
                    </div>} */}
                            <h5>{pageTitle}</h5>
                            {errorMsg !== '' && <div className="erroralert" >{errorMsg}</div>}
                            {sucMsg !== '' && <div className="successtext">{sucMsg}</div>}
                            {loading ? <LoadingSpinner /> : <form>
                                <div className="row">
                                    <div className="col-md-2">
                                        <label>Tenant name:</label>
                                    </div>
                                    <div className="col-md-3">
                                        <input className="form-control" type="text" id="tenantName" name="tenantName" onChange={this.changeHandler} value={this.state.tenantName} />
                                        <div className="errortext">{this.state.tenantnameError}</div>
                                    </div>
                                    <div className="col-md-2">
                                        <label>Domain:</label>
                                    </div>
                                    <div className="col-md-3">
                                        <input className="form-control" type="text" id="domain" name="domain" onChange={this.changeHandler} value={this.state.domain} />
                                        <div className="errortext">{this.state.domainError}</div>
                                    </div>
                                </div>
                                {/* <div className="row">
                                    <div className="col-md-12">
                                        <h6>SSO Configuration Details</h6>
                                    </div>
                                </div>
                                <div className="row" >
                                    <div className="col-md-2">
                                        <label>URL:</label>
                                    </div>
                                    <div className="col-md-3">
                                        <input readOnly={Readonlyflag} className="form-control" type="text" id="url" name="url" onChange={this.changeHandler} value={this.state.url} />
                                        <div className="errortext">{this.state.urlError}</div>
                                    </div>                                    
                                </div> */}
                                <div className="row">
                                    <div className="col-md-12">
                                        <h6>Admin Details</h6>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-2">
                                        <label>Admin User ID:</label>
                                    </div>
                                    <div className="col-md-3">
                                        {showtext && <input className="form-control" type="text" id="tenantEmail" name="tenantEmail" onChange={this.changeHandler} value={this.state.tenantEmail} />}
                                        {showdropdown && <select id="tenantEmail" name="tenantEmail" value={this.state.tenantEmail} onChange={this.ddchangeHandler.bind(this)}>
                                            {optionItems}
                                        </select>}
                                        <div className="errortext">{this.state.tenantEmailError}</div> 
                                    </div>
                                    <div className="col-md-2">
                                        <label>Contact Number:</label>
                                    </div>
                                    <div className="col-md-3">
                                        <input className="form-control" type="text" id="contactNo" name="contactNo" onChange={this.changeHandler} defaultValue={this.state.contactNo} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-2">
                                    </div>
                                    <div className="col-md-10">
                                        {connecting === false && <button type='submit' onClick={this.submitHandler} className="btn btn-primary">{buttonTitle}</button>}
                                        {connecting && <button className="btn btn-primary" variant="primary" disabled>
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />{buttonTitles}</button>}
                                    </div>
                                </div>
                            </form>}

                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}
const mapstateToProps = state => {
    return {
        createdBy: state.myLogin.username,
        token: state.myLogin.token
    }
}
export default connect(
    mapstateToProps)
    (TenantAdd)

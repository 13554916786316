import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Header from '../../Molecules/Header/Header';
import Footer from '../../Molecules/Footer/Footer';
import Button from '../../Atoms/Button/Button';
import axios from 'axios';
import { getHeaders } from '../../Api/ClientUtils';
// import API from '../../Api/ApiPath';
import strings from '../../../lang';
import Modal from '../../Molecules/Modal/Modal';
import LogoutMessage from '../../Molecules/Toaster/LogoutMessage';
import Leftsidebar from '../../Molecules/Leftsidebar/Leftsidebar';
class ApplicationList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            posts: [],
            sucMsg: '',
            errorMsg: '',
            tenantId: '',
            tenantName: '',
            pending: true,
            logouttoast: false,
        }
        this.deleteHandler = this.deleteHandler.bind(this)
    }
    componentDidMount() {
        this.listApps()
        this.listTenants()
    }
    listApps() {
        axios.get('list-tenant-application/'+ this.props.match.params.tenantId, { headers: getHeaders(this.props.token) })
        // API.get('list-tenant-application/' + this.props.match.params.tenantId)
            .then(response => {
                this.setState({ posts: response.data.tenantAppList, pending: false })
            })
            .catch(async error => {
                this.setState({ posts: [], pending: false })
                if (error.response.data.statusCode === 401) {
                    this.setState({ logouttoast: true })
                    await this.sleep(5000);
                    this.props.history.push("/logout");
                }
            })
    }
    listTenants() {
        const tenantId = this.props.match.params.tenantId
        axios.post('view-tenant', { tenantId }, { headers: getHeaders(this.props.token) })
            .then(response => {
                this.setState({ tenantName: response.data.tenant.tenantName, tenantId: response.data.tenant.tenantId })
            })
            .catch(async error => {
                if (error.response.data.statusCode === 401) {
                    this.setState({ logouttoast: true })
                    await this.sleep(5000);
                    this.props.history.push("/logout");
                }                
            })
    }

    deleteHandler() {
        const tenantAppId = this.state.DeleteID.toString()
        axios.post('delete-tenant-application', { tenantAppId }, { headers: getHeaders(this.props.token) })
            .then(response => {
                document.getElementById(`deleteModal`).style.display = 'none';
                this.setState({ sucMsg: 'Successfully deleted the application.' })
                // window.setTimeout(() => { this.setState({ sucMsg: '' }) }, 5000)
                this.listApps()
            })
            .catch(async error => {
                if (error.response.data.statusCode === 401) {
                    this.setState({ logouttoast: true })
                    await this.sleep(5000);
                    this.props.history.push("/logout");
                }
                this.setState({ errorMsg: 'Error - ' + error.response.data.message })
                // window.setTimeout(() => { this.setState({ errorMsg: '' }) }, 5000)
            })
    }
    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    render() {
        const { posts, tenantName, errorMsg, sucMsg, tenantId, pending } = this.state
        const columns = [
            {
                name: 'Application',
                selector: 'tenantAppName',
                sortable: true,
                width: '160px'
            },
            {
                name: 'Application URL',
                selector: 'applicationUrl',
                sortable: true
            },

            {
                name: 'Status',
                selector: 'tenantAppStatus',
                sortable: true,
                width: '120px'
            },
            {
                name: 'Edit',
                selector: 'id',
                cell: row => <Link to={"/application-add/" + tenantId + "/" + row.tenantAppId}><Button>Setup</Button></Link>,
                ignoreRowClick: true,
                allowOverflow: true,
                width: '120px'
            },
            {
                name: 'Delete',
                selector: 'id',
                cell: (row) => <div
                    className="center"
                    onClick={e => {
                        e.stopPropagation();
                        document.getElementById(`deleteModal`).style.display = 'block';
                        this.setState({ DeleteID: row.tenantAppId })

                    }}
                    role="presentation"
                >
                    <img
                        src={require('../../../assets/images/delete.svg')}
                        alt={strings.delete}
                        className="delete-img"
                    />
                </div>,
                ignoreRowClick: true,
                allowOverflow: true,
                width: '120px'
            }
        ];
        return (
            <div id="container">
                <Header />
                <div id="wraps">
                <Leftsidebar tenantId={this.state.tenantId} />
                <div id="contents">
                    <LogoutMessage logoutToast={this.state.logouttoast} />
                    <div className="container topmargin">                        
                        <div className="row">
                            <div className="col-md-10"><h5>Applications of <i>{tenantName}</i></h5></div>
                            <div className="col-md-2"> <Link to={"/application-add/" + tenantId}><Button>New Application</Button></Link></div>
                        </div>                        
                        {errorMsg !== '' && <div className="erroralert" >{errorMsg}</div>}
                        {sucMsg !== '' && <div className="successtext">{sucMsg}</div>}
                        <DataTable
                            columns={columns}
                            data={posts}
                            pagination={true}
                            highlightOnHover={true}
                            dense={true}
                            responsive={true}
                            progressPending={pending}
                            noHeader={true}
                        />
                    </div>
                    <Modal
                        headerText={strings.confirmDelete}
                        uniqueModalName="deleteModal"
                        message={strings.requestDeleteAppConfirmMessage}
                        minusText={strings.cancel}
                        minusAction={() => {
                            document.getElementById('deleteModal').style.display = 'none';
                        }}
                        plusText={strings.confirm}
                        plusAction={() => {
                            this.deleteHandler(this.state.DeleteID);
                        }}
                    />
                </div>
                </div>
                <Footer />
            </div>
        );
    }
}
const mapstateToProps = state => {
    return {
        token: state.myLogin.token
    }
}
export default connect(
    mapstateToProps)
    (ApplicationList)
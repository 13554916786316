import axios from 'axios';
// import { connect } from 'react-redux';
import { getHeaders } from './ClientUtils';


let user = JSON.parse(sessionStorage.getItem('persist:root')) ? JSON.parse(sessionStorage.getItem('persist:root')) : '';
// const API = (props) => {
let API, accessToken,backendbaseURL= '';
if (process.env.REACT_APP_BASEURL !== '' && process.env.REACT_APP_BASEURL !== undefined)
    backendbaseURL = process.env.REACT_APP_BASEURL || 'http://localhost:8080/api/';
else {
    backendbaseURL = window.location.protocol + '//' + window.location.hostname + '/serve/api';
    // var href = window.location.href;
    // var pathname = window.location.pathname
    // backendbaseURL = href.replace(pathname, '') + '/serve/api';
    }

if (user !== '') {
    const mylogin = JSON.parse(user.myLogin);
    const token = mylogin.token;
    const userTenantId = JSON.parse(user.userTenantId);
    accessToken = userTenantId.savedIdpAccessToken;
    // const azureDetails = JSON.parse(user.azureDetails);
    // if (azureDetails.userPrincipalName !== '') {
    //     accessToken = azureDetails.ADsavedIdpAccessToken;
    // }
    API = axios.create({
        baseURL: backendbaseURL,
        headers: getHeaders(token, accessToken)

    });
} else {
    API = axios.create({
        baseURL: backendbaseURL
    });

}
// }

export default API;


// import React from "react";
// import axios from 'axios';
// import { connect } from 'react-redux';
// import { getHeaders } from './ClientUtils';

// class API extends React.Component {
//     render() {
//         let API, accessToken, token;
//         if (this.props.token !== '') {
//             // const mylogin = JSON.parse(user.myLogin);
//             token = this.props.token;
//             console.log(token)
//             // const userTenantId = JSON.parse(user.userTenantId);
//             accessToken = this.props.savedIdpAccessToken;
//             API = axios.create({
//                 baseURL: process.env.REACT_APP_BASEURL || `http://localhost:8080/api/`,
//                 headers: getHeaders(token, accessToken)
//             });
//         } else {
//             API = axios.create({
//                 baseURL: process.env.REACT_APP_BASEURL || `http://localhost:8080/api/`
//             });
//         }
//         console.log(API);
//         return API;

//     }
// }
// const mapstateToProps = state => {
//     return {
//         token: state.myLogin.token,
//         savedIdpAccessToken: state.userTenantId.savedIdpAccessToken,
//     }
// }
// export default connect(
//     mapstateToProps)
//     (API)


import React from 'react';
import { connect } from 'react-redux';
import { withAuth0 } from "@auth0/auth0-react";
import HeaderAuth from '../../Molecules/Header/HeaderAuth';
import Footer from '../../Molecules/Footer/Footer';
import './AuthUserAdd.css';
import API from '../../Api/ApiPath';
import { Redirect } from "react-router-dom";
import { getSSOHeaders } from '../../Api/ClientUtils';
import { userLogout } from '../../Pages/Logout/LogoutActions';
import LogoutMessage from '../../Molecules/Toaster/LogoutMessage';
import LoadingSpinner from '../../Molecules/LoadingSpinner/LoadingSpinner';
import Spinner from 'react-bootstrap/Spinner';
class Checky extends React.Component {
    render() {
        return (
            <input
                type="checkbox"
                name={this.props.name}
                value={this.props.name}
                checked={this.props.checked}
                onChange={this.props.onChange}
                className="checkbox-input-item"
            />
        );
    }
}
class AuthUserAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userEmail: '',
            tenantId: '',
            applications: [],
            tapplications: [],
            tenantName: '',
            applic_array: [],
            arr: [],
            checkString: '',
            redirect: false,
            BearerToken: '',
            logouttoast: false,
            userEmailError: '',
            sucMsg: '',
            errorMsg: '',
            loading: false,
            apploading: true,
            connecting: false,
        }
    }
    validate = () => {
        let isError = false;
        const errors = {
            userEmailError: ""
        };
        if (this.state.userEmail === '') {
            isError = true;
            errors.userEmailError = "Operational User ID is required";
        }
        this.setState({
            ...this.state,
            ...errors
        });
        return isError;
    };
    changeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value })

    }
    onChange = (event) => {
        var checkString = '';
        const checkeds = document.getElementsByClassName('checkbox-input-item');
        // console.log("checkeds"+JSON.stringify(checkeds)) 
        for (let i = 0; i < checkeds.length; i++) {
            if (checkeds[i].checked) {
                // checkedArr.push(checkeds[i].value);
                checkString += checkeds[i].value + ",";
            }
        }
        checkString = checkString.replace(/,\s*$/, "");

        this.setState({ applIds: checkString })
        this.toggle(+event.target.name);
    }
    toggle = (x) => {
        const oldArr = this.state.applic_array;
        const index = oldArr.indexOf(x);
        const newArr = oldArr.slice();
        if (index === -1) {
            newArr.push(x);
        } else {
            newArr.splice(index, 1);
        }
        this.setState({ applic_array: newArr });
    }

    listApps() {        
        this.setState({ apploading: true }, () => {
            API.get('list-tenant-application/' + this.props.savedtenantId, { headers: getSSOHeaders(this.state.BearerToken,this.props.userPrincipalName) })
                .then(response => {
                    this.setState({ applications: response.data.tenantAppList, apploading: false })
                })
                .catch(error => {
                    this.setState({ apploading: false })
                })
        });
    }
    submitHandler = (e) => {
        e.preventDefault()
        const err = this.validate();
        if (!err) {
            const tenantUserId = this.props.saveTenantUserId
            if (tenantUserId !== '' && tenantUserId !== undefined) {
                this.setState({ connecting: true }, () => {
                API.post('update-tenant-user', this.state, { headers: getSSOHeaders(this.state.BearerToken,this.props.userPrincipalName) })
                    .then(response => {
                        // this.props.history.push("/tenants-list-user/" + this.props.savedtenantId);
                        this.setState({ redirect: true, connecting: false  });
                    })
                    .catch(error => {
                        this.setState({ errorMsg: 'Error - ' + error.response.data.message, connecting: false  })
                        // window.setTimeout(() => { this.setState({ errorMsg: '' }) }, 5000)
                    })
                });
            } else {
                this.setState({ connecting: true }, () => {
                API.post('add-tenant-user', this.state, { headers: getSSOHeaders(this.state.BearerToken,this.props.userPrincipalName) })
                    .then(response => {
                        // this.props.history.push("/tenants-list-user/" + this.props.savedtenantId);
                        this.setState({ redirect: true, connecting: false  });
                    })
                    .catch(error => {
                        this.setState({ errorMsg: 'Error - ' + error.response.data.message, connecting: false  })
                        // window.setTimeout(() => { this.setState({ errorMsg: '' }) }, 5000)
                    })
                });
            }
        }
    }
    async componentDidMount(_props) {
        try {
            const { getAccessTokenSilently } = this.props.auth0;
            const token = await getAccessTokenSilently();
            const tenantId = this.props.savedtenantId;
            const tenantUserId = this.props.saveTenantUserId;
            this.setState({
                tenantId: tenantId, tenantUserId: tenantUserId, createdBy: this.props.createdBy,
                BearerToken: token
            });
            if (tenantUserId !== '' && tenantUserId !== undefined) {
                this.setState({ loading: true }, () => {
                    API.post('view-tenant-user', { tenantUserId }, { headers: getSSOHeaders(token,this.props.userPrincipalName) })
                        .then(response => {
                            var str = response.data.tenant.applIds;
                            var applic_array = str.split(',').map(Number);
                            this.setState({ applic_array: applic_array, loading: false, });
                            this.setState({ arr: applic_array.slice(), userEmail: response.data.tenant.userEmail })
                        })
                        .catch(error => {
                            this.setState({ loading: false })
                        })
                });
            }
            this.listApps()
        }
        catch (err) {
            this.setState({ errorMsg: 'Error - ' + JSON.stringify(err) })
            // window.setTimeout(() => { this.setState({ errorMsg: '' }) }, 5000)
            // if (err.errorCode === "consent_required" || err.errorCode === "interaction_required" || err.errorCode === "login_required") {
            this.setState({ logouttoast: true })
            this.logout()
            // }
        }
    }
    render() {
        const { userEmail, errorMsg, tenantUserId, redirect, loading, apploading,connecting } = this.state
        if (redirect) {
            return <Redirect to='/user-list' />;
        }
        let tapplications = Array.from(this.state.applications);
        let checkItems, checkItemsfalse = ''
        if (tapplications.length !== 0) {
            checkItems = tapplications.map((tapplication, index) =>
                <div key={tapplication.tenantAppId} >
                    <Checky key={index} name={tapplication.tenantAppId} checked={this.state.applic_array.indexOf(tapplication.tenantAppId) > -1} value={tapplication.tenantAppId} className="checkbox-input-item" onChange={this.onChange} /> {tapplication.tenantAppName} ( {tapplication.applicationUrl} )
                  </div>
            );
        } else {
            checkItemsfalse = "No applications added to tenant";
        }
        let pageTitle, buttonTitle, buttonTitles
        if (tenantUserId !== '' && tenantUserId !== undefined) {
            pageTitle = 'Manage Tenant User';
            buttonTitle = 'Update';
            buttonTitles = 'Updating...';
        } else {
            pageTitle = 'Create Tenant User';
            buttonTitle = 'Create';
            buttonTitles = 'Creating...';
        }
        return (
            <div id="container">
                <HeaderAuth />
                <LogoutMessage logoutToast={this.state.logouttoast} />
                <div className="container topmargin">
                    <h4>{pageTitle}</h4>
                    {errorMsg !== '' && <div className="erroralert" >{errorMsg}</div>}
                    {(loading || apploading) ? <LoadingSpinner /> : <form>
                        <div className="row">
                            <div className="col-md-4">
                                <label>Operational User ID:</label>
                            </div>
                            <div className="col-md-4">
                                <input className="form-control" type="text" name="userEmail" value={userEmail} onChange={this.changeHandler} />
                                <div className="errortext">{this.state.userEmailError}</div>
                            </div>
                            <div className="col-md-4">
                                ( <i>Specify the company User ID enabled with SSO</i> )
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <label>Allow Access to Applications</label>
                            </div>
                            <div className="col-md-8">
                                <i>{checkItemsfalse}</i>
                                <span id="applications" className="input-item-container">
                                    {checkItems}
                                </span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                            </div>
                            <div className="col-md-8">
                                {connecting === false && <button type='submit' onClick={this.submitHandler} className="btn btn-primary">{buttonTitle}</button>}
                                {connecting && <button className="btn btn-primary" variant="primary" disabled>
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                    />{buttonTitles}</button>}
                            </div>
                        </div>
                    </form>}

                </div>
                <Footer />
            </div>
        );
    }
    setErrorMessage(message, debug) {
        this.setState({
            error: { message: message, debug: debug }
        });
    }
    async logout(props) {
        await this.sleep(5000);
        this.userAgentApplication.logout();
        props.userLogout();
    }
    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
}

const mapstateToProps = state => {
    return {
        createdBy: state.userTenantId.saveIdpUsername,
        savedtenantId: state.userTenantId.savedtenantId,
        saveTenantUserId: state.userId.saveTenantUserId,
        userPrincipalName: state.userTenantId.saveIdpUsername
    }
}
const mapDispatchToProps = dispatch => {
    return {
        userLogout: () => dispatch(userLogout())
    }
}
export default withAuth0(connect(
    mapstateToProps,
    mapDispatchToProps)
    (AuthUserAdd))

import {
    SAVE_ISAUTHENTICATED
  } from '../Constants/Type';
  
  const INITIAL_STATE = {
    isAuthenticate: false
  };
  const myRoute = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case SAVE_ISAUTHENTICATED:
        return {
          ...state,
          isAuthenticate: action.payload
        };      
      default:
        return state;
    }
  };
  
  export default myRoute;
  
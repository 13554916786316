import React from 'react';
import { connect } from 'react-redux';
import Header from '../../Molecules/Header/Header';
import Footer from '../../Molecules/Footer/Footer';
import axios from 'axios';
import { getHeaders } from '../../Api/ClientUtils';
// import API from '../../Api/ApiPath';
import LogoutMessage from '../../Molecules/Toaster/LogoutMessage';
class ChangePassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPassword: '',
            newPassword: '',
            confirmpassword: '',
            logouttoast: false,
            sucMsg:'',
            errorMsg:''
        }
    }
    validate = () => {
        let isError = false;
        const errors = {
            currentPasswordError: "",
            newPasswordError: "",
            confirmpasswordError: "",
            errorMsg: '',
            sucMsg: '',
        };
        if (this.state.currentPassword === '') {
            isError = true;
            errors.currentPasswordError = "Current Password is required";
        }
        if (this.state.newPassword === '') {
            isError = true;
            errors.newPasswordError = "New Password is required";
        }
        if (this.state.confirmpassword === '') {
            isError = true;
            errors.confirmpasswordError = "Confirm New Password is required";
        }
        if (this.state.confirmpassword !== this.state.newPassword) {
            isError = true;
            errors.confirmpasswordError = "The passwords don't match";
        }
        if (this.state.newPassword.length < 4) {
            isError = true;
            errors.newPasswordError = "New Password required and should be atleast 4 characters long";
        }
        this.setState({
            ...this.state,
            ...errors
        });
        return isError;
    };
    changeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    submitHandler = (e) => {
        e.preventDefault()
        const err = this.validate();
        if (!err) {
            this.setState({
                currentPasswordError: "",
                newPasswordError: "",
                confirmpasswordError: ""
            });
            axios.post('change-password', this.state, { headers: getHeaders(this.props.token) })  
            // API.post('change-password', this.state)
                .then(response => {
                    this.setState({ sucMsg: 'Your password has been reset successfully.' })
                    // window.setTimeout(()=>{this.setState({sucMsg:''})},5000)
                })
                .catch(async error => {
                    if (error.response.data.statusCode === 401) { 
                        this.setState({ logouttoast: true })
                        await this.sleep(5000);
                    this.props.history.push("/logout"); }
                    this.setState({ errorMsg: 'Error updating the password.'})
                    // window.setTimeout(()=>{this.setState({errorMsg:''})},5000)
                })
        }
        this.setState({
            currentPassword: '',
            newPassword: '',
            confirmpassword: ''
        });
    }
    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    render() {
        const { errorMsg, sucMsg } = this.state
        let pageTitle, buttonTitle
        pageTitle = 'Reset Password';
        buttonTitle = 'Update Password';
        return (
            <div id="container">
                <Header />
                <LogoutMessage logoutToast={this.state.logouttoast} />
                <div className="container topmargin">
                    <h4>{pageTitle}</h4>
                    {errorMsg !=='' &&<div className="erroralert" >{errorMsg}</div>}
                    {sucMsg !=='' &&<div className="successtext">{sucMsg}</div>}
                    <form>
                        <div className="row">
                            <div className="col-md-3">
                                <label>Current Password:</label>
                            </div>
                            <div className="col-md-4">
                                <input className="form-control" type="password" id="currentPassword" name="currentPassword" onChange={this.changeHandler} value={this.state.currentPassword} />
                                <div className="errortext">{this.state.currentPasswordError}</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">
                                <label>New Password:</label>
                            </div>
                            <div className="col-md-4">
                                <input className="form-control" type="password" id="newPassword" name="newPassword" onChange={this.changeHandler} value={this.state.newPassword} />
                                <div className="errortext">{this.state.newPasswordError}</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">
                                <label>Confirm New Password:</label>
                            </div>
                            <div className="col-md-4">
                                <input className="form-control" type="password" id="confirmpassword" name="confirmpassword" onChange={this.changeHandler} value={this.state.confirmpassword} />
                                <div className="errortext">{this.state.confirmpasswordError}</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">
                            </div>
                            <div className="col-md-9">
                                <button type='submit' onClick={this.submitHandler} className="btn btn-primary">{buttonTitle}</button>
                            </div>
                        </div>
                    </form>

                </div>
                <Footer />
            </div>
        );
    }
}
const mapstateToProps = state => {
    return {token: state.myLogin.token
    }
}
export default connect(
    mapstateToProps)
    (ChangePassword);
export const SAVE_USERNAME_REQUESTED = 'SAVE_USERNAME_REQUESTED';
export const SAVE_ISAUTHENTICATED = 'SAVE_ISAUTHENTICATED';
export const SAVE_TOKEN_REQUESTED = 'SAVE_TOKEN_REQUESTED';
export const SAVE_TENANT_ID = 'SAVE_TENANT_ID';
export const USER_LOGOUT = 'USER_LOGOUT';
export const SAVE_TENANTID_REQUESTED = 'SAVE_TENANTID_REQUESTED';
export const SAVE_IDP_TOKEN = 'SAVE_IDP_TOKEN';
export const SAVE_IDP_CLIENTID = 'SAVE_IDP_CLIENTID';
export const SAVE_IDP_USERNAME = 'SAVE_IDP_USERNAME';
export const SAVE_TENANT_USER_ROLE = 'SAVE_TENANT_USER_ROLE';
export const SAVE_AD_TENANT_ID = 'SAVE_AD_TENANT_ID';
export const SAVE_AD_IDP_TOKEN = 'SAVE_AD_IDP_TOKEN';
export const SAVE_AD_IDP_CLIENTID = 'SAVE_AD_IDP_CLIENTID';
export const SAVE_AD_TENANT_USER_ROLE = 'SAVE_AD_TENANT_USER_ROLE';
export const SAVE_USER_PRINCIPAL_NAME = 'SAVE_USER_PRINCIPAL_NAME';
export const SAVE_TENANT_APP_ID = 'SAVE_TENANT_APP_ID';
export const SAVE_TENANT_USER_ID = 'SAVE_TENANT_USER_ID';
export const SAVE_OPERA_APP_ID = 'SAVE_OPERA_APP_ID';
export const SAVE_APP_ID = 'SAVE_APP_ID';

export const USER_FETCH_REQUESTED = 'USER_FETCH_REQUESTED';
export const USER_FETCH_SUCCEEDED = 'USER_FETCH_SUCCEEDED';
export const USER_FETCH_FAILED = 'USER_FETCH_FAILED';
export const ADD_USER_REQUESTED = 'ADD_USER_REQUESTED';
export const ADD_USER_SUCCEEDED = 'ADD_USER_SUCCEEDED';
export const ADD_USER_FAILED = 'ADD_USER_FAILED';

import {
  SAVE_USERNAME_REQUESTED,
  SAVE_TOKEN_REQUESTED,
  USER_FETCH_REQUESTED
} from '../../Constants/Type';

export const saveUsername = text => ({
  type: SAVE_USERNAME_REQUESTED,
  payload: text
});
export const saveToken = text => ({
  type: SAVE_TOKEN_REQUESTED,
  payload: text
});
export const fetchUserRequest = () => ({
  type: USER_FETCH_REQUESTED
});

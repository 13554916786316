import {
    SAVE_TENANT_ID,
    SAVE_IDP_TOKEN,
    SAVE_IDP_CLIENTID,
    SAVE_TENANT_USER_ROLE,
    SAVE_IDP_USERNAME
  } from '../../../Constants/Type';
  
  const INITIAL_STATE = {
    savedtenantId: '',
    savedIdpAccessToken: '',
    saveClientId: '',
    savedTenantUserRole: '',
    saveIdpUsername: '',
    // username: '',
    // token: ''
  };
  const userTenantId = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case SAVE_TENANT_ID:
        return {
          ...state,
          savedtenantId: action.payload
        };
      case SAVE_IDP_TOKEN:
        return {
          ...state,
          savedIdpAccessToken: action.payload,
        };
      case SAVE_IDP_CLIENTID:
        return {
          ...state,
          saveClientId: action.payload
        };
      case SAVE_TENANT_USER_ROLE:
        return {
          ...state,
          savedTenantUserRole: action.payload
        };
      case SAVE_IDP_USERNAME:
        return {
          ...state,
          saveIdpUsername: action.payload
        };
      default:
        return state;
    }
  };
  
  export default userTenantId;
  
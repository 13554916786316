import React from 'react';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function Leftsidebarfn(props) {
  const tenantId = props.tenantId
  if (props.tenantId) {
    return (
      <div id="left-sidebar">
        <Nav defaultActiveKey="/home" className="flex-column" >
          <Nav.Link as={Link} to={"/tenant-user-list/" + tenantId}>Users</Nav.Link>
          <Nav.Link as={Link} to={"/application-list/" + tenantId}>Applications</Nav.Link>
          <Nav.Link as={Link} to={"/tenant-add/" + tenantId}>Tenant Profile</Nav.Link>
        </Nav>
      </div>
    );
  }
  else {
    return null;
  }
}

export default class Leftsidebar extends React.Component {
  render() {
    return (
      <Leftsidebarfn tenantId={this.props.tenantId} />
    );
  }
}
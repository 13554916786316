import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from 'react-redux';

const ProtectedRoute = (props) => {
    const { component: Component, ...rest } = props;
    const isAuthenticate = props.isAuthenticate;
    return (
        <Route {...rest} render={props => {
            // console.log(JSON.stringify(props))  
            //        console.log("isAuth value="+ isAuthenticate)
            if (isAuthenticate === true) {
                return <Component {...props} />;                
            } else {
                return (
                    <Redirect to={{ pathname: "/admin-login", state: { from: props.location } }} />
                );
            }
        }} />
    );
}

const mapstateToProps = state => {
    return {
        isAuthenticate: state.myRoute.isAuthenticate
    }
}
export default connect(
    mapstateToProps
)(ProtectedRoute)






/*
Header File for Auth0 SSO pages 
*/
import React, { useState } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { NavLink as RouterNavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from 'react-redux';
import {
  Collapse,
  NavbarToggler,
  NavItem,
  NavLink,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import { useAuth0 } from "@auth0/auth0-react";
// import { userLogout } from '../../Pages/Logout/LogoutActions';
const HeaderAuthOperaUser = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    logout,
  } = useAuth0();
  const toggle = () => setIsOpen(!isOpen);

  const logoutWithRedirect = () => {
    // props.userLogout();
    // sessionStorage.removeItem('persist:root');
    // localStorage.removeItem('persist:root');
    // window.history.replaceState(null, null, "/");
    logout({
      federated:true,
      returnTo: window.location.origin
    });
  }
  const tenantUserRole = props.savedTenantUserRole

  return (
    <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
      <Navbar.Brand>SCIM Manager</Navbar.Brand>
      <NavbarToggler onClick={toggle} />
      <Collapse isOpen={isOpen} navbar>
        <Nav className="mr-auto" navbar>
          {isAuthenticated && tenantUserRole === 'ADMIN' && (
            <NavItem>
              <NavLink
                tag={RouterNavLink}
                to="/user-list"
                exact
                activeClassName="router-link-exact-active"
              >
                Users
                    </NavLink>
            </NavItem>
          )}
          {isAuthenticated && (
            <NavItem>
              <NavLink
                tag={RouterNavLink}
                to="/app-list"
                exact
                activeClassName="router-link-exact-active"
              >
                Applications
                    </NavLink>
            </NavItem>
          )}
          {isAuthenticated && (
            <NavItem>
              <NavLink
                tag={RouterNavLink}
                to="/tenant-profile"
                exact
                activeClassName="router-link-exact-active"
              >
                Tenant Profile
                    </NavLink>
            </NavItem>
          )}
        </Nav>
        <Nav className="d-none d-md-block" navbar>
          {!isAuthenticated && (
            <NavItem>
              <Button
                id="qsLoginBtn"
                color="primary"
                className="btn-margin"
                onClick={() => loginWithRedirect()}
              >
                Log in
                    </Button>
            </NavItem>
          )}
          {isAuthenticated && (
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret id="profileDropDown">
                <img
                  src={user.picture}
                  alt="Profile"
                  className="nav-user-profile rounded-circle"
                  width="50"
                />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem header>{user.email}</DropdownItem>
                <DropdownItem
                  id="qsLogoutBtn"
                  onClick={() => logoutWithRedirect()}
                >
                  <FontAwesomeIcon icon="power-off" className="mr-3" /> Log
                        out
                      </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          )}
        </Nav>
        {!isAuthenticated && (
          <Nav className="d-md-none" navbar>
            <NavItem>
              <Button
                id="qsLoginBtn"
                color="primary"
                block
                onClick={() => loginWithRedirect({})}
              >
                Log in
                    </Button>
            </NavItem>
          </Nav>
        )}
        {isAuthenticated && (
          <Nav
            className="d-md-none justify-content-between"
            navbar
            style={{ minHeight: 90 }}
          >
            <NavItem>
              <span className="user-info">
                <img
                  src={user.picture}
                  alt="Profile"
                  className="nav-user-profile d-inline-block rounded-circle mr-3"
                  width="50"
                />
                <h6 className="d-inline-block">{user.email}</h6>
              </span>
            </NavItem>            
            <NavItem>
              <FontAwesomeIcon icon="power-off" className="mr-3" />
              <RouterNavLink
                to="#"
                id="qsLogoutBtn"
                onClick={() => logoutWithRedirect()}
              >
                Log out
                    </RouterNavLink>
            </NavItem>
          </Nav>
        )}
      </Collapse>
    </Navbar>

  );
};
// const mapDispatchToProps = dispatch => {
//   return {
//     userLogout: () => dispatch(userLogout())
//   }
// }
const mapstateToProps = state => {
  return {
    savedTenantUserRole: state.userTenantId.savedTenantUserRole
  }
}

export default connect(
  mapstateToProps)
  (HeaderAuthOperaUser)
import React from "react";
import '../../../Pages/Login/Login.css';
import HeaderAuth from '../../../Molecules/Header/HeaderAuth';
import { connect } from 'react-redux';
import { savedTenantId, savedIdpAccessToken, savedTenantUserRole, saveIdpUsername } from './HomeActions';
import { saveUsername, saveToken } from '../../../Pages/Login/LoginActions';
import { saveIsAuthenticated } from '../../../Router/ProtectedRouteActions';
import API from '../../../Api/ApiPath';
import { getSSOHeaders } from '../../../Api/ClientUtils';
import LogoutButton from './logout-button';
import LoginButton from './login-button';
import LoadingSpinner from '../../../Molecules/LoadingSpinner/LoadingSpinner';
import { withAuth0 } from "@auth0/auth0-react";
class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMsg: '',
      loading: true,
      userRole: "0",
      error: null,
      loaded: false,
    }
  }
  async componentDidMount(props) {
    try {
      this.props.saveIdpUsername('');
      this.props.savedIdpAccessToken('');
      this.props.saveUsername('');
      this.props.saveToken('');
      this.props.saveIsAuthenticated(false);
      const { getAccessTokenSilently } = this.props.auth0;
      const token = await getAccessTokenSilently();
      this.props.savedIdpAccessToken(token);
      this.fetchtenant(token);      
    }
    catch (err) {

    }
  }
  fetchtenant(token){
    this.setState({ loading: true }, () => {
    API.post('fetch-tenantid', {}, { headers: getSSOHeaders(token, this.props.auth0.user.email) })
        .then(response => {
          const tID = response.data.tenant.tenantId.toString()
          this.props.savedTenantId(tID);
          const TenantUserRole = response.data.tenant.tenantUserRole
          this.props.savedTenantUserRole(TenantUserRole);
          const { user } = this.props.auth0;
          const { email } = user;
          this.props.saveIdpUsername(email);
          this.setState({ userRole: TenantUserRole, loaded: true,loading:false })
        })
        .catch(error => {
          this.props.savedTenantUserRole("0");
          if (error.response.data.statusCode === 409) {
            this.setState({ userRole: "99", loaded: true, errorMsg: error.response.data.message })            
          } else {
            this.setState({ userRole: "0", loaded: true,loading:false })
          }
        })
      });
  }
  render() {
    
    const { isAuthenticated, isLoading, error } = this.props.auth0;   
    if(isLoading || (isAuthenticated && this.state.userRole === "0")){
      return <LoadingSpinner />;
    }
    if (error) {
      return <div>Oops... {error.message}</div>;
    }
    if (isAuthenticated && this.state.userRole === "99") {
      return (
        <div className="container topmargin">
          <p>No user found under the tenant or Fetching user Tenant ID has issue.</p>
          <LogoutButton />
        </div>
      );
    }
    if (isAuthenticated) {
      const { user } = this.props.auth0;
      const { name } = user;
      return (
        <div id="container">
          <HeaderAuth />
          <div className="container topmargin">
            <h4>Welcome {name}!</h4>
            <p>Use the navigation bar at the top of the page to get started.</p>
          </div>
        </div>
      );
    }
    if (!isAuthenticated) {
      return (
        <div id="logincontainer">
          <div className="login-page">
            <div className="form">
              <form className="login-form" >
                <h5>Use your company account to login and connect to your panel.</h5>
                <LoginButton />
              </form>
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapstateToProps = state => {
  return {
    savedtenantId: state.userTenantId.savedtenantId,
    savedIdpAccessToken: state.userTenantId.savedIdpAccessToken,
    saveIdpUsername: state.userTenantId.saveIdpUsername,
    savedTenantUserRole: state.userTenantId.savedTenantUserRole,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    saveIdpUsername: text => dispatch(saveIdpUsername(text)),
    savedTenantId: text => dispatch(savedTenantId(text)),
    savedIdpAccessToken: text => dispatch(savedIdpAccessToken(text)),
    savedTenantUserRole: text => dispatch(savedTenantUserRole(text)),
    // saveClientId: text => dispatch(saveClientId(text)),
    saveUsername: text => dispatch(saveUsername(text)),
    saveToken: text => dispatch(saveToken(text)),
    saveIsAuthenticated: bool => dispatch(saveIsAuthenticated(bool))
  }
}
export default withAuth0(connect(
  mapstateToProps,
  mapDispatchToProps)
  (Home));

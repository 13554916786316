import {
    SAVE_TENANT_ID,
    SAVE_IDP_TOKEN,
    SAVE_IDP_CLIENTID,
    SAVE_TENANT_USER_ROLE,
    SAVE_IDP_USERNAME
  } from '../../../Constants/Type';
  
  export const savedTenantId = text => ({
    type: SAVE_TENANT_ID,
    payload: text
  });
  export const savedIdpAccessToken = text => ({
    type: SAVE_IDP_TOKEN,
    payload: text
  });
  export const saveClientId = text => ({
    type: SAVE_IDP_CLIENTID,
    payload: text
  });
  export const savedTenantUserRole = text => ({
    type: SAVE_TENANT_USER_ROLE,
    payload: text
  });
  export const saveIdpUsername = text => ({
    type: SAVE_IDP_USERNAME,
    payload: text
  });
  
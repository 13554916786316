import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
require('dotenv').config()

// axios.defaults.baseURL = process.env.REACT_APP_BASEURL;
if (process.env.REACT_APP_BASEURL === '' || process.env.REACT_APP_BASEURL === undefined) {
    axios.defaults.baseURL = window.location.protocol + '//' + window.location.hostname + '/serve/api';
}
else {
    axios.defaults.baseURL = process.env.REACT_APP_BASEURL || 'http://localhost:8080/api/';
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React from 'react';
import { connect } from 'react-redux';
import HeaderAuth from '../../Molecules/Header/HeaderAuth';
import Footer from '../../Molecules/Footer/Footer';
import API from '../../Api/ApiPath';
import { getSSOHeaders } from '../../Api/ClientUtils';
import { userLogout } from '../../Pages/Logout/LogoutActions';
import LogoutMessage from '../../Molecules/Toaster/LogoutMessage';
import LoadingSpinner from '../../Molecules/LoadingSpinner/LoadingSpinner';
import { withAuth0 } from "@auth0/auth0-react";
class AuthTenantProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tenantName: '',
            domain: '',
            sucMsg:'',
            errorMsg: '',
            tenantId: '',
            BearerToken: '',
            logouttoast: false,
            loading: true,
            tenantUserList: [],
            posts: [],
        }

    }
    changeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    async componentDidMount(props) {
        // try {
            const { getAccessTokenSilently } = this.props.auth0;
            const token = await getAccessTokenSilently();
            const tenantId = this.props.savedtenantId
            if (tenantId !== '' && tenantId !== undefined) {
                this.setState({ loading: true }, () => {
                    API.post('view-tenant', { tenantId }, { headers: getSSOHeaders(token,this.props.userPrincipalName) })
                        .then(response => {
                            this.setState({
                                tenantName: response.data.tenant.tenantName,
                                domain: response.data.tenant.domain,
                                url: response.data.tenant.url,
                                contactNo: response.data.tenant.tenantContactNo,
                                tenantUserList: response.data.tenant.tenantUserList,
                                tenantEmail: response.data.tenant.userEmail,
                                loading: false,
                                tenantId: this.props.savedtenantId,
                                BearerToken: token
                            })
                        })
                        .catch(error => {
                            this.setState({ errorMsg: 'Error - ' + error.response.data.message, loading: false })
                            // window.setTimeout(() => { this.setState({ errorMsg: '' }) }, 5000)
                        })
                });
            }
        // }
        // catch (err) {
        //     this.setState({ errorMsg: 'Error - ' + JSON.stringify(err) })
        //     // window.setTimeout(() => { this.setState({ errorMsg: '' }) }, 5000)
        //     // if (err.errorCode === "consent_required" || err.errorCode === "interaction_required" || err.errorCode === "login_required") {
        //     this.setState({ logouttoast: true })
        //     this.logout()
        //     // }
        // }

    }
    validate = () => {
        let isError = false;
        const errors = {
            tenantnameError: "",
            domainError: "",
            urlError: ""
        };
        if (this.state.tenantName === '') {
            isError = true;
            errors.tenantnameError = "Tenant name required";
        }
        if (this.state.domain === '') {
            isError = true;
            errors.domainError = "Domain required";
        }
        if (this.state.url === '') {
            isError = true;
            errors.urlError = "URL required";
        }
        this.setState({
            ...this.state,
            ...errors
        });
        return isError;
    };
    submitHandler = (e) => {
        e.preventDefault()
        const err = this.validate();
        if (!err) {
            this.setState({
                tenantnameError: "",
                domainError: "",
                urlError: ""
            });
            const tenantId = this.state.tenantId
            if (tenantId !== '' && tenantId !== undefined) {
                API.post('update-tenant', this.state, { headers: getSSOHeaders(this.state.BearerToken,this.props.userPrincipalName) })
                    .then(response => {
                        this.setState({ sucMsg: 'Tenant details updated successfully.' })
                        // window.setTimeout(() => { this.setState({ sucMsg: '' }) }, 5000)
                    })
                    .catch(error => {
                        this.setState({ errorMsg: 'Error - ' + error.response.data.message })
                        // window.setTimeout(() => { this.setState({ errorMsg: '' }) }, 5000)
                    })
            }
        }
    }
    render() {
        const {  sucMsg, errorMsg, loading } = this.state
        let pageTitle, buttonTitle, Readonlyflag, showtext, showdropdown, hiderows
        hiderows = false;
        Readonlyflag = this.props.savedTenantUserRole === 'USER' ? true : false;        
        showtext = false;
        showdropdown = true;
        pageTitle = 'Company Profile';
        buttonTitle = 'Update';
        let tenantUserLists = this.state.tenantUserList;
        let optionItems = tenantUserLists.map((tenantUserList) =>
            <option key={tenantUserList.userId} value={tenantUserList.email}>{tenantUserList.email}</option>
        );
        return (

            <div id="container">
                <HeaderAuth />
                <LogoutMessage logoutToast={this.state.logouttoast} />

                <div className="container topmargin">
                    <h4>{pageTitle}</h4>
                    {errorMsg !== '' && <div className="erroralert" >{errorMsg}</div>}
                    {sucMsg !== '' && <div className="successtext">{sucMsg}</div>}
                    {loading ? <LoadingSpinner /> : <form>
                        <div className="row">
                            <div className="col-md-2">
                                <label>Tenant name:</label>
                            </div>
                            <div className="col-md-4">
                                <input readOnly={Readonlyflag} className="form-control" type="text" id="tenantName" name="tenantName" onChange={this.changeHandler} defaultValue={this.state.tenantName} />
                            </div>
                            <div className="col-md-2">
                                <label>Domain:</label>
                            </div>
                            <div className="col-md-4">
                                <input readOnly={Readonlyflag} className="form-control" type="text" id="domain" name="domain" onChange={this.changeHandler} defaultValue={this.state.domain} />
                            </div>
                        </div>

                        {/* {!Readonlyflag && <div className="row">
                            <div className="col-md-12">
                                <h5>SSO Configuration Details</h5>
                            </div>
                        </div>}
                        {!Readonlyflag && <div className="row" >
                            <div className="col-md-2">
                                <label>URL:</label>
                            </div>
                            <div className="col-md-3">
                                <input readOnly className="form-control" type="text" id="url" name="url" onChange={this.changeHandler} value={this.state.url} />
                                <div className="errortext">{this.state.urlError}</div>
                            </div>
                        </div>} */}
                        {!Readonlyflag && hiderows && <div className="row">
                            <div className="col-md-2">
                                <label>Admin User ID:</label>
                            </div>
                            <div className="col-md-3">
                                {showtext && <input className="form-control" type="text" id="tenantEmail" name="tenantEmail" onChange={this.changeHandler} value={this.state.tenantEmail} />}
                                {showdropdown && <select id="tenantEmail" name="tenantEmail" value={this.state.tenantEmail} onChange={this.ddchangeHandler.bind(this)}>
                                    {optionItems}
                                </select>}
                            </div>
                            <div className="col-md-2">
                                <label>Contact Number:</label>
                            </div>
                            <div className="col-md-3">
                                <input className="form-control" type="text" id="contactNo" name="contactNo" onChange={this.changeHandler} value={this.state.contactNo} />
                            </div>
                        </div>}
                        {!Readonlyflag && 
                        <div className="row">
                            <div className="col-md-2">
                            </div>
                            <div className="col-md-10">
                                <button type='submit' onClick={this.submitHandler} className="btn btn-primary">{buttonTitle}</button>
                            </div>
                        </div>}
                    </form>}

                </div>
                <Footer />
            </div>
        );
    }

    setErrorMessage(message, debug) {
        this.setState({
            error: { message: message, debug: debug }
        });
    }
    async logout(props) {
        await this.sleep(5000);
        this.userAgentApplication.logout();
        props.userLogout();
    }
    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

}
const mapstateToProps = state => {
    return {
        savedtenantId: state.userTenantId.savedtenantId,
        savedTenantUserRole: state.userTenantId.savedTenantUserRole,
        userPrincipalName: state.userTenantId.saveIdpUsername
    }
}
const mapDispatchToProps = dispatch => {
    return {
        userLogout: () => dispatch(userLogout())
    }
}
export default withAuth0(connect(
    mapstateToProps,
    mapDispatchToProps)
    (AuthTenantProfile))

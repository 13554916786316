import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import HeaderAuth from '../../Molecules/Header/HeaderAuth';
import Footer from '../../Molecules/Footer/Footer';
import Button from '../../Atoms/Button/Button';
import API from '../../Api/ApiPath';
import { saveOperaAppId } from './AuthAppListActions';
import { getSSOHeaders } from '../../Api/ClientUtils';
import { userLogout } from '../../Pages/Logout/LogoutActions';
import LogoutMessage from '../../Molecules/Toaster/LogoutMessage';
import { withAuth0 } from "@auth0/auth0-react";
class AuthAppList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            posts: [],
            tenantName: '',
            errorMsg: '',
            optionvalue: 'select',
            tenantId: '',
            pending: true,
            BearerToken: '',
            sucMsg: '',
            logouttoast: false,
        }
        this.tenantAppIdHandler = this.tenantAppIdHandler.bind(this);
    }
    async componentDidMount() {
        try {
            const { getAccessTokenSilently } = this.props.auth0;
            const token = await getAccessTokenSilently();
            this.setState({ BearerToken: token })
        }
        catch (err) {
            this.setState({ errorMsg: 'Error - ' + JSON.stringify(err) })
            // if (err.errorCode === "consent_required" || err.errorCode === "interaction_required" || err.errorCode === "login_required") {
            this.setState({ logouttoast: true })
            this.logout()
        }
        // }
        this.listApps()
    }

    listApps() {
        API.post('fetch-application-details', {}, { headers: getSSOHeaders(this.state.BearerToken,this.props.userPrincipalName) })
            .then(response => {
                this.setState({
                    posts: response.data.tenantAppList,
                    pending: false,
                    hidetable: response.data.tokenExpFlag
                })
            })
            .catch(error => {
                this.setState({
                    posts: [],
                    pending: false
                })
            })
    }
    tenantTokenRefresh(AppId) {
        const tenantAppId = AppId.toString()
        API.post('refresh-token', { tenantAppId }, { headers: getSSOHeaders(this.state.BearerToken,this.props.userPrincipalName) })
            .then(response => {
                this.setState({ sucMsg: 'Successfully refreshed token' })
                // window.setTimeout(()=>{this.setState({sucMsg:''})},5000)
                this.listApps()
            })
    }

    tenantAppIdHandler(tenantAppId) {
        const AppId = tenantAppId.toString()
        this.props.saveOperaAppId(AppId);
    }
    timeConvert(min) {
        if (min <= 60) {
            return min + " min(s)";
        } else {
            return Math.floor(min / 60) + " hr(s) " + min % 60 + " min(s)"
        }
    }
    render() {
        const { posts, errorMsg, pending, sucMsg } = this.state
        const columns = [
            {
                name: 'Application',
                selector: 'tenantAppName',
                sortable: true,
                width: '150px'
            },
            {
                name: 'URL',
                selector: 'applicationUrl',
                sortable: true
            },
            {
                name: 'Members',
                selector: 'id',
                cell: row => <Link onClick={(e) => {
                    e.stopPropagation();
                    this.tenantAppIdHandler(row.tenantAppId)
                }} to={"/member-list"}><Button>Members</Button></Link>,
                ignoreRowClick: true,
                allowOverflow: true,
                width: '110px'
            },
            {
                name: 'Action',
                selector: 'id',
                cell: row => <Link onClick={(e) => {
                    e.stopPropagation();
                    this.tenantAppIdHandler(row.tenantAppId)
                }} to={"/app-add/"}><Button>Setup</Button></Link>,
                ignoreRowClick: true,
                allowOverflow: true,
                width: '90px'
            },
            {
                name: 'Refresh/Update',
                cell: (row) => <div>{row.diffTime !== "Expired" && <div onClick={(e) => {
                    e.stopPropagation();
                    this.tenantTokenRefresh(row.tenantAppId)
                }} ><Button>Refresh Token</Button></div>}
                    {row.diffTime === "Expired" && <div><Link onClick={(e) => {
                        e.stopPropagation();
                        this.tenantAppIdHandler(row.tenantAppId)
                    }} to={"/app-add"}><Button>Update Token</Button></Link></div>}</div>,
                width: '130px',
                omit: this.state.hidetable
            },
            {
                name: 'Expiry In',
                selector: 'diffTime',
                cell: (row) => <div>{row.diffTime === "Expired" && <div className="errortext">Expired</div>}
                    {row.diffTime !== "Expired" && <div>{this.timeConvert(row.diffTime)}</div>}</div>,
                width: '125px',
                omit: this.state.hidetable
            }
        ];

        return (
            <div id="container">
                <HeaderAuth />
                <LogoutMessage logoutToast={this.state.logouttoast} />
                <div className="container topmargin">
                    <div className="row">
                        <div className="col-md-10"><h4>Applications</h4></div>
                    </div>
                    {errorMsg !== '' && <div className="erroralert" >{errorMsg}</div>}
                    {sucMsg !== '' && <div className="successtext">{sucMsg}</div>}
                    <DataTable
                        columns={columns}
                        data={posts}
                        pagination={true}
                        highlightOnHover={true}
                        dense={true}
                        responsive={true}
                        progressPending={pending}
                        noHeader={true}
                    />
                </div>
                <Footer />
            </div>
        );
    }
    setErrorMessage(message, debug) {
        this.setState({
            error: { message: message, debug: debug }
        });
    }
    async logout(props) {
        await this.sleep(5000);
        props.userLogout();
    }
    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
}
const mapstateToProps = state => {
    return {
        userPrincipalName: state.userTenantId.saveIdpUsername
    }
}
const mapDispatchToProps = dispatch => {
    return {
        saveOperaAppId: text => dispatch(saveOperaAppId(text)),
        userLogout: () => dispatch(userLogout())
    }
}
export default withAuth0(
    connect(mapstateToProps, mapDispatchToProps)
        (AuthAppList))


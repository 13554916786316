/* eslint func-names: 0 no-console: 0 */
import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';
import storageSession from 'redux-persist/lib/storage/session';

import logger from 'redux-logger';

import { rootReducer } from './index';

import { log } from '../config';


const persistConfig = {
  key: 'root',
  storage: storageSession
  
};

const middlewares = [];
if (log === true) {
  middlewares.push(logger);
}


const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
  persistedReducer,
  applyMiddleware(...middlewares)
);

export const persistor = persistStore(store);

import React from 'react';
import { connect } from 'react-redux';
import Header from '../../Molecules/Header/Header';
import Footer from '../../Molecules/Footer/Footer';
import './TenantUserAdd.css';
import axios from 'axios';
import { getHeaders } from '../../Api/ClientUtils';
// import API from '../../Api/ApiPath';
import LogoutMessage from '../../Molecules/Toaster/LogoutMessage';
import LoadingSpinner from '../../Molecules/LoadingSpinner/LoadingSpinner';
import Leftsidebar from '../../Molecules/Leftsidebar/Leftsidebar';
import Spinner from 'react-bootstrap/Spinner';
class Checky extends React.Component {
    render() {
        return (
            <input
                type="checkbox"
                name={this.props.name}
                value={this.props.name}
                checked={this.props.checked}
                onChange={this.props.onChange}
                className="checkbox-input-item"
            />
        );
    }
}
class TenantUserAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userEmail: '',
            tenantId: '',
            applications: [],
            tapplications: [],
            tenantName: '',
            applic_array: [],
            arr: [],
            checkString: '',
            logouttoast: false,
            userEmailError: '',
            errorMsg: '',
            sucMsg: '',
            loading: false,
            apploading:false,
            connecting:false,
        }
        
    }
    validate = () => {
        let isError = false;
        const errors = {
            userEmailError: "",
            errorMsg: '',
            sucMsg: '',
        };
        if (this.state.userEmail === '') {
            isError = true;
            errors.userEmailError = "Operational User ID is required";
        }
        // if (!(/^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/.test(this.state.userEmail)))
        // {
        //     isError = true;
        //     errors.userEmailError = "Operational User ID should be an email address";
        // }
        this.setState({
            ...this.state,
            ...errors
        });
        return isError;
    };
    changeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value })

    }
    onChange = (event) => {
        var checkString = '';
        const checkeds = document.getElementsByClassName('checkbox-input-item');
        for (let i = 0; i < checkeds.length; i++) {
            if (checkeds[i].checked) {
                // checkedArr.push(checkeds[i].value);
                checkString += checkeds[i].value + ",";
            }
        }
        checkString = checkString.replace(/,\s*$/, "");

        this.setState({ applIds: checkString })
        this.toggle(+event.target.name);
    }
    toggle = (x) => {
        const oldArr = this.state.applic_array;
        const index = oldArr.indexOf(x);
        const newArr = oldArr.slice();
        if (index === -1) {
            newArr.push(x);
        } else {
            newArr.splice(index, 1);
        }
        this.setState({ applic_array: newArr });
    }

    handleChange = (e) => {
        // var checkedArr = [], val = [];
        var checkString = '';
        this.setState({ [e.target.name]: e.target.value });
        const checkeds = document.getElementsByName('appl');
        // const checkeds = document.getElementsByTagName('checkbox')  
        for (let i = 0; i < checkeds.length; i++) {
            if (checkeds[i].checked) {
                // checkedArr.push(checkeds[i].value);
                checkString += checkeds[i].value + ",";
            }
        }
        checkString = checkString.replace(/,\s*$/, "");
        this.setState({ applIds: checkString })

    }
    listApps() {
        this.setState({ apploading: true }, () => {
        axios.get('list-tenant-application/' + this.props.match.params.tenantId, { headers: getHeaders(this.props.token) })  
        // API.get('list-tenant-application/' + this.props.match.params.tenantId)
            .then(response => {
                this.setState({ applications: response.data.tenantAppList, apploading: false })
            })
            .catch(async error => {
                if (error.response.data.statusCode === 401) {
                    this.setState({ logouttoast: true })
                    await this.sleep(5000);
                    this.props.history.push("/logout");
                }
                this.setState({ apploading: false })
            })
        });
    }
    submitHandler = (e) => {
        e.preventDefault()
        const err = this.validate();
        if (!err) {
            const tenantUserId = this.props.match.params.tenantUserId
            if (tenantUserId !== '' && tenantUserId !== undefined) {
                this.setState({ connecting: true }, () => {
                axios.post('update-tenant-user',this.state, { headers: getHeaders(this.props.token) })  
                // API.post('update-tenant-user', this.state)
                    .then(response => {
                        this.props.history.push("/tenant-user-list/" + this.props.match.params.tenantId);
                    })
                    .catch(async error => {
                        if (error.response.data.statusCode === 401) {
                            this.setState({ logouttoast: true })
                            await this.sleep(5000);
                            this.props.history.push("/logout");
                        }
                        this.setState({ errorMsg: 'Error - ' + error.response.data.message, connecting:false })
                        // window.setTimeout(() => { this.setState({ errorMsg: '' }) }, 5000)
                    })
                });
            } else {
                this.setState({ connecting: true }, () => {
                axios.post('add-tenant-user',this.state, { headers: getHeaders(this.props.token) })  
                // API.post('add-tenant-user', this.state)
                    .then(response => {
                        this.props.history.push("/tenant-user-list/" + this.props.match.params.tenantId);
                    })
                    .catch(async error => {
                        if (error.response.data.statusCode === 401) {
                            this.setState({ logouttoast: true })
                            await this.sleep(5000);
                            this.props.history.push("/logout");
                        }
                        this.setState({ errorMsg: 'Error - ' + error.response.data.message, connecting:false })
                        // window.setTimeout(() => { this.setState({ errorMsg: '' }) }, 5000)
                    })
                });
            }
        }
    }
    listTenants() {
        const tenantId = this.props.match.params.tenantId
        axios.post('view-tenant',{ tenantId }, { headers: getHeaders(this.props.token) })  
        // API.post('view-tenant', { tenantId })
            .then(response => {
                const tID = response.data.tenant.tenantId.toString()
                this.setState({
                    tenantName: response.data.tenant.tenantName,
                    tenantId: tID
                })
            })
            .catch(async error => {
                if (error.response.data.statusCode === 401) {
                    this.setState({ logouttoast: true })
                    await this.sleep(5000);
                    this.props.history.push("/logout");
                }
            })
    }
    componentDidMount(_props) {
        const tenantUserId = this.props.match.params.tenantUserId
        this.setState({ tenantUserId: tenantUserId, createdBy: this.props.createdBy })
        if (tenantUserId !== '' && tenantUserId !== undefined) {
            this.setState({ loading: true }, () => {
                axios.post('view-tenant-user',{ tenantUserId }, { headers: getHeaders(this.props.token) })  
                // API.post('view-tenant-user', { tenantUserId })
                    .then(response => {
                        var str = response.data.tenant.applIds;
                        var applic_array = str.split(',').map(Number);
                        this.setState({
                            applic_array: applic_array,
                            arr: applic_array.slice(),
                            userEmail: response.data.tenant.userEmail,
                            loading: false
                        });
                    })
                    .catch(async error => {
                        if (error.response.data.statusCode === 401) {
                            this.setState({ logouttoast: true })
                            await this.sleep(5000);
                            this.props.history.push("/logout");
                        }
                        this.setState({ loading: false })
                    })
            });
        }
        this.listApps()
        this.listTenants()
    }
    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    render() {
        const { userEmail, errorMsg, sucMsg, tenantName, tenantUserId, loading, tenantId, apploading, connecting } = this.state
        let tapplications = Array.from(this.state.applications);
        let checkItems, checkItemsfalse = ''

        if (tapplications.length !== 0) {
            checkItems = tapplications.map((tapplication, index) =>
                <div key={tapplication.tenantAppId} >
                    <Checky key={index} name={tapplication.tenantAppId} checked={this.state.applic_array.indexOf(tapplication.tenantAppId) > -1} value={tapplication.tenantAppId} className="checkbox-input-item" onChange={this.onChange} /> {tapplication.tenantAppName} ( {tapplication.applicationUrl} )
                  </div>
            );
        } else {
            checkItemsfalse = "No applications added to tenant";
        }
        let pageTitle, buttonTitle, buttonTitles
        if (tenantUserId !== '' && tenantUserId !== undefined) {
            pageTitle = 'Manage Tenant User';
            buttonTitle = 'Update';
            buttonTitles = 'Updating...';
        } else {
            pageTitle = 'Create Tenant User';
            buttonTitle = 'Create';
            buttonTitles = 'Creating...';
        }
        return (
            <div id="container">
                <Header />
                <div id="wraps">
                <Leftsidebar tenantId={tenantId} />
                <div id="contents">
                <LogoutMessage logoutToast={this.state.logouttoast} />
                <div className="container topmargin">
                    {/* <div className="row InnerNavbar">
                        <div className="col-md-12">
                            <Breadcrumbs tenantId={tenantId} />
                        </div>
                    </div> */}
                    <h5>{pageTitle}</h5>
                    {errorMsg !== '' && <div className="erroralert" >{errorMsg}</div>}
                    {sucMsg !== '' && <div className="successtext">{sucMsg}</div>}
                    {(loading || apploading) ? <LoadingSpinner /> : <form>
                        <div className="row">
                            <div className="col-md-4">
                                <label>Tenant Name: </label>
                            </div>
                            <div className="col-md-4">
                                <label>{tenantName}</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <label>Operational User ID:</label>
                            </div>
                            <div className="col-md-4">
                                <input className="form-control" type="text" name="userEmail" value={userEmail} onChange={this.changeHandler} />
                                <div className="errortext">{this.state.userEmailError}</div>
                            </div>
                            <div className="col-md-4">
                                ( <i>Specify the company User ID enabled with SSO</i> )
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <label>Allow Access to Applications:</label>
                            </div>
                            <div className="col-md-8">
                                <i> {checkItemsfalse}</i>
                                <span id="applications" className="input-item-container">
                                    {checkItems}
                                </span>
                            </div>
                        </div>
                        {/* <div className="row">
                            <div className="col-md-10">
                                <h6><i>** specify the company User ID enabled with SSO</i></h6>
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col-md-4">
                            </div>
                            <div className="col-md-8">
                            {connecting === false &&<button type='submit' onClick={this.submitHandler} className="btn btn-primary">{buttonTitle}</button>}
                            {connecting &&<button className="btn btn-primary" variant="primary" disabled>
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />{buttonTitles}</button>}
                            </div>
                        </div>
                    </form>}

                </div>
                </div>
                </div>
                <Footer />
            </div>
        );
    }
}
const mapstateToProps = state => {
    return {
        createdBy: state.myLogin.username,
        token: state.myLogin.token
    }
}
export default connect(
    mapstateToProps)
    (TenantUserAdd)

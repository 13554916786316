/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

import Button from '../../Atoms/Button/Button';
import './Modal.css';

const Modal = ({
  headerText,
  message,
  minusText,
  minusAction,
  plusText,
  plusAction,
  uniqueModalName
}) => {
  return (
    <div id={`${uniqueModalName}`} className="modal">
      <div className="modal-content">
        <div className="modal-header">
          <span
            className="close"
            onClick={() => {
              document.getElementById(`${uniqueModalName}`).style.display =
                'none';
            }}
            role="presentation"
          >
            &times;
          </span>
          <p>{headerText}</p>
        </div>
        <div className="modal-body">
          <p>{message}</p>
          <div className="modal-actions">
            {minusText ? (
              <Button className="modal-minus-btn" action={minusAction}>
                {minusText}
              </Button>
            ) : (
              ''
            )}
            {plusText ? (
              <Button className="modal-plus-btn" action={plusAction}>{plusText}</Button>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="modal-footer" />
      </div>
    </div>
  );
};

export default Modal;

import React from 'react';
import { connect } from 'react-redux';
import Header from '../../Molecules/Header/Header';
import Footer from '../../Molecules/Footer/Footer';
import axios from 'axios';
import { getHeaders } from '../../Api/ClientUtils';
// import API from '../../Api/ApiPath';
import LogoutMessage from '../../Molecules/Toaster/LogoutMessage';
class Config extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expiryTime: '',
            smtpHost: '',
            smtpPort: '',
            smtpStarttls: '',
            selectedOption :'',
            senderEmail: '',
            senderPassword: '',
            senderName: '',
            logouttoast: false,
            sucMsg: '',
            errorMsg: ''
        }
        this.onValueChange = this.onValueChange.bind(this);

    }
    validate = () => {
        let isError = false;
        const errors = {
            expiryTimeError: "",
            smtpHostError: "",
            smtpPortError: '',
            senderEmailError: '',
            senderPasswordError: '',
            senderNameError: '',
            errorMsg: '',
            sucMsg: '',
        };
        if (this.state.expiryTime === '') {
            isError = true;
            errors.expiryTimeError = "Expiry time is required";
        }
        if (this.state.smtpHost === '') {
            isError = true;
            errors.smtpHostError = "Host is required";
        }
        if (this.state.smtpPort === '') {
            isError = true;
            errors.smtpPortError = "Port is required";
        }
        if (this.state.senderEmail === '') {
            isError = true;
            errors.senderEmailError = "Username is required";
        }
        if (this.state.senderPassword === '') {
            isError = true;
            errors.senderPasswordError = "Password is required";
        }
        if (this.state.senderName === '') {
            isError = true;
            errors.senderNameError = "Sender name is required";
        }
        this.setState({
            ...this.state,
            ...errors
        });
        return isError;
    };
    changeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    numericChangeHandler = (e) => {
        this.setState({ [e.target.name]: Number(e.target.value) })
    }
    onValueChange= (e) => {
        var isHidden = e.target.value;
        var isHiddenBool = (isHidden === 'true');
        this.setState({ selectedOption : e.target.value, smtpStarttls:isHiddenBool  })
    }

    submitHandler = (e) => {
        e.preventDefault()
        const err = this.validate();
        if (!err) {

            this.setState({
                expiryTimeError: "",
                smtpHostError: "",
                smtpPortError: ""
            });
            axios.post('save-config-details', this.state, { headers: getHeaders(this.props.token) })  
            // API.post('save-config-details', this.state)
                .then(response => {
                    this.setState({ sucMsg: 'Your configuration details has been added successfully.' })
                    // window.setTimeout(()=>{this.setState({sucMsg:''})},5000)
                })
                .catch(async error => {
                    if (error.response.data.statusCode === 401) {
                        this.setState({ logouttoast: true })
                        await this.sleep(5000);
                        this.props.history.push("/logout");
                    }
                    this.setState({ errorMsg: 'Error while updating the configuration details.' })
                    // window.setTimeout(()=>{this.setState({errorMsg:''})},5000)
                })
        }
    }
    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    componentDidMount() {
        this.setState({ loading: true }, () => {
            axios.get('fetch-config-details',{ headers: getHeaders(this.props.token) })  
            // API.get('fetch-config-details')
                .then(response => {
                    this.setState({
                        expiryTime: response.data.config.expiryTime,
                        smtpHost: response.data.config.smtpHost,
                        smtpPort: response.data.config.smtpPort,
                        selectedOption: response.data.config.tlsEnable.toString(),
                        smtpStarttls: response.data.config.tlsEnable,
                        senderEmail: response.data.config.senderEmail,
                        senderPassword: response.data.config.senderPassword,
                        senderName: response.data.config.senderName,
                        loading: false
                    })
                })
                .catch(error => {
                    this.setState({ loading: false })
                    if (error.response.data.statusCode === 401) {
                        this.setState({ logouttoast: true })
                        this.sleep(5000);
                        this.props.history.push("/logout");
                    }
                })
        });
    }
    render() {
        const { errorMsg, sucMsg } = this.state
        let pageTitle, buttonTitle
        pageTitle = 'Set Configuration';
        buttonTitle = 'Update Configuration';
        return (
            <div id="container">
                <Header />
                <LogoutMessage logoutToast={this.state.logouttoast} />
                <div className="container topmargin">
                    <h4>{pageTitle}</h4>
                    {errorMsg !== '' && <div className="erroralert" >{errorMsg}</div>}
                    {sucMsg !== '' && <div className="successtext">{sucMsg}</div>}
                    <form>
                        <div className="row">
                            <div className="col-md-3">
                                <label>Token expiry time in minutes:</label>
                            </div>
                            <div className="col-md-4">
                                <input className="form-control" type="number" id="expiryTime" name="expiryTime" onChange={this.numericChangeHandler} value={this.state.expiryTime} />
                                <div className="errortext">{this.state.expiryTimeError}</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <h6>SMTP Details</h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">
                                <label>Host:</label>
                            </div>
                            <div className="col-md-4">
                                <input className="form-control" type="text" id="smtpHost" name="smtpHost" onChange={this.changeHandler} value={this.state.smtpHost} />
                                <div className="errortext">{this.state.smtpHostError}</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">
                                <label>Port:</label>
                            </div>
                            <div className="col-md-4">
                                <input className="form-control" type="number" id="smtpPort" name="smtpPort" onChange={this.numericChangeHandler} value={this.state.smtpPort} />
                                <div className="errortext">{this.state.smtpPortError}</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">
                                <label>Security (Enable SSL/TLS):</label>
                            </div>
                            <div className="col-md-4">
                                <input type="radio" value="true" name="smtpStarttls"
                                    checked={this.state.selectedOption  === 'true'}
                                    onChange={this.onValueChange} /> True
                                <input type="radio" value="false" name="smtpStarttls"
                                    checked={this.state.selectedOption  === 'false'}
                                    onChange={this.onValueChange} /> False
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">
                                <label>Username:</label>
                            </div>
                            <div className="col-md-4">
                                <input className="form-control" type="text" id="senderEmail" name="senderEmail" onChange={this.changeHandler} value={this.state.senderEmail} />
                                <div className="errortext">{this.state.senderEmailError}</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">
                                <label>Password:</label>
                            </div>
                            <div className="col-md-4">
                                <input className="form-control" type="password" id="senderPassword" name="senderPassword" onChange={this.changeHandler} value={this.state.senderPassword} />
                                <div className="errortext">{this.state.senderPasswordError}</div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-md-3">
                                <label>Sender Name:</label>
                            </div>
                            <div className="col-md-4">
                                <input className="form-control" type="text" id="senderName" name="senderName" onChange={this.changeHandler} value={this.state.senderName} />
                                <div className="errortext">{this.state.senderNameError}</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">
                            </div>
                            <div className="col-md-9">
                                <button type='submit' onClick={this.submitHandler} className="btn btn-primary">{buttonTitle}</button>
                            </div>
                        </div>
                    </form>

                </div>
                <Footer />
            </div>
        );
    }
}
const mapstateToProps = state => {
    return {token: state.myLogin.token
    }
}
export default connect(
    mapstateToProps)
    (Config);
import React from 'react';
import { connect } from 'react-redux';
import Header from '../../Molecules/Header/Header';
import Footer from '../../Molecules/Footer/Footer';
import axios from 'axios';
import { getHeaders } from '../../Api/ClientUtils';
// import API from '../../Api/ApiPath';
import LogoutMessage from '../../Molecules/Toaster/LogoutMessage';
import LoadingSpinner from '../../Molecules/LoadingSpinner/LoadingSpinner';
// import Breadcrumbs from '../../Molecules/Breadcrumbs/Breadcrumbs';
import Leftsidebar from '../../Molecules/Leftsidebar/Leftsidebar';
import Spinner from 'react-bootstrap/Spinner';
// import Button from '../../Atoms/Button/Button';
class ApplicationAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tenantId: '',
            appId: '',
            tenantAppId: '',
            tenantApplId: '',
            applUrl: '',
            key: '',
            apiClientId: '',
            apiSecretId: '',
            applId: 'select',
            posts: [],
            applications: [],
            tenantName: '',
            logouttoast: false,
            sucMsg: '',
            errorMsg: '',
            loading: false,
            connecting: false,
            scimEndPoint: '',
            clientIdLabel: '',
            secretIdLabel: '',
            applicationselected: false
        }
        this.listApps()
        this.listTenants()
    }
    changeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }
    ddchangeHandler(event) {
        this.setState({
            applId: event.target.value,
            apiClientId: '',
            apiSecretId: '',
        });
        this.applicationLabel(event.target.value);
    }
    applicationLabel(appID) {
        axios.get('application-token-label/' + appID, { headers: getHeaders(this.props.token) })
            .then(response => {
                this.setState({
                    clientIdLabel: response.data.tenantAppl.clientIdLabel,
                    secretIdLabel: response.data.tenantAppl.secretIdLabel,
                    applicationselected: true
                })
                if (response.data.tenantAppl.clientIdLabel === '' && response.data.tenantAppl.secretIdLabel === '') {
                    this.setState({ applicationselected: false })
                }
            })
            .catch(async error => {
                if (error.response.data.statusCode === 401) {
                    this.setState({ logouttoast: true })
                    await this.sleep(5000);
                    this.props.history.push("/logout");
                }
                // this.setState({ errorMsg: 'Error listing applications' })
            })
    }
    validate = () => {
        let isError = false;
        const errors = {
            AppNameError: "",
            applUrlError: "",
            apiClientIdError: "",
            apiSecretIdError: "",
            // applicationIdError:"",
            errorMsg: '',
            sucMsg: '',
        };
        // if (this.state.AppName === '') {
        //     isError = true;
        //     errors.AppNameError = "Application name required";
        // }
        if (this.state.applUrl === '') {
            isError = true;
            errors.applUrlError = "Application URL is required";
        }
        // if (this.state.apiClientId === '' || this.state.apiClientId === undefined) {
        //     isError = true;
        //     errors.apiClientIdError = "Client ID is required";
        // }
        if (this.state.apiSecretId.length === 0 || this.state.apiSecretId === undefined) {
            isError = true;
            errors.apiSecretIdError = "Field is required";
        }
        this.setState({
            ...this.state,
            ...errors
        });
        return isError;
    };

    listTenants() {
        const tenantId = this.props.match.params.tenantId
        axios.post('view-tenant', { tenantId }, { headers: getHeaders(this.props.token) })
            // API.post('view-tenant', { tenantId })
            .then(response => {
                this.setState({ tenantName: response.data.tenant.tenantName })
            })
            .catch(async error => {
                if (error.response.data.statusCode === 401) {
                    this.setState({ logouttoast: true })
                    await this.sleep(5000);
                    this.props.history.push("/logout");
                }
            })
    }
    submitHandler = (e) => {
        e.preventDefault()
        const err = this.validate();
        console.log(this.state)
        if (!err) {
            this.setState({
                AppNameError: "",
                applUrlError: "",
                apiClientIdError: "",
                apiSecretIdError: "",
                // applicationIdError:"",
                errorMsg: '',
                sucMsg: '',
            });
            const tenantAppId = this.props.match.params.tenantAppId
            if (tenantAppId !== '' && tenantAppId !== undefined) {
                this.setState({ connecting: true }, () => {
                    axios.post('update-tenant-application', this.state, { headers: getHeaders(this.props.token) })
                        // API.post('update-tenant-application', this.state)
                        .then(response => {
                            // this.props.history.push("/application-list/" + this.state.tenantId);
                            this.setState({ sucMsg: 'Tenant Application updated successfully.', connecting: false })
                            // window.setTimeout(() => { this.setState({ sucMsg: '' }) }, 5000)
                        })
                        .catch(async error => {
                            if (error.response.data.statusCode === 401) {
                                this.setState({ logouttoast: true })
                                await this.sleep(5000);
                                this.props.history.push("/logout");
                            }
                            this.setState({ errorMsg: 'Error - ' + error.response.data.message, connecting: false })
                            // window.setTimeout(() => { this.setState({ errorMsg: '' }) }, 10000)
                        })
                });
            } else {
                this.setState({ connecting: true }, () => {
                    axios.post('add-tenant-application', this.state, { headers: getHeaders(this.props.token) })
                        // API.post('add-tenant-application', this.state)
                        .then(response => {
                            // this.props.history.push("/application-list/" + this.state.tenantId);
                            this.setState({ sucMsg: 'Tenant Application added successfully.', connecting: false })
                        })
                        .catch(async error => {
                            if (error.response.data.statusCode === 401) {
                                this.setState({ logouttoast: true })
                                await this.sleep(5000);
                                this.props.history.push("/logout");
                            }
                            this.setState({ errorMsg: 'Error - ' + error.response.data.message, connecting: false })
                            // window.setTimeout(() => { this.setState({ errorMsg: '' }) }, 10000)
                        })
                });
            }
        }
    }
    generateKeyHandler = (e) => {
        e.preventDefault()
        axios.post('generate-token', {}, { headers: getHeaders(this.props.token) })
            // API.post('generate-token')
            .then(response => {
                this.setState({ key: response.data.token })
                // window.setTimeout(() => { this.setState({ sucMsg: '' }) }, 5000)
            })
            .catch(async error => {
                if (error.response.data.statusCode === 401) {
                    this.setState({ logouttoast: true })
                    await this.sleep(5000);
                    this.props.history.push("/logout");
                }
                this.setState({ errorMsg: 'Error - ' + error.response.data.message })
                // window.setTimeout(() => { this.setState({ errorMsg: '' }) }, 5000)
            })
    }
    componentDidMount(_props) {
        const tenantId = this.props.match.params.tenantId
        const tenantAppId = this.props.match.params.tenantAppId
        // var href = window.location.href;
        // var pathname = window.location.pathname
        // var url = href.replace(pathname, '');
        // var href2 = process.env.REACT_APP_BASEURL || `http://localhost:8080/api/` ;
        // var strArray = href2.split(":");
        // var scimEndPoint = strArray[0] +":"+strArray[1] +'/scim/v2';
        var scimEndPoint;
        if (process.env.REACT_APP_BASEURL === '' || process.env.REACT_APP_BASEURL === undefined) {
            scimEndPoint = window.location.protocol + '//' + window.location.hostname + '/serve/scim/v2';
        }
        else {
            var href = process.env.REACT_APP_BASEURL || 'http://localhost:8080/api/';
            var strArray = href.split(":");
            scimEndPoint = strArray[0] + ":" + strArray[1] + '/scim/v2';
        }
        this.setState({ tenantId: tenantId, tenantAppId: tenantAppId, tenantApplId: tenantAppId, tenantAppCreatedBy: this.props.tenantAppCreatedBy, scimEndPoint: scimEndPoint })
        if (tenantAppId !== '' && tenantAppId !== undefined) {
            this.setState({ loading: true }, () => {
                axios.post('view-tenant-application', { tenantAppId }, { headers: getHeaders(this.props.token) })
                    // API.post('view-tenant-application', { tenantAppId })
                    .then(response => {
                        const appId = response.data.tenantAppl.applId.toString()
                        this.applicationLabel(appId);
                        this.setState({
                            applId: appId,
                            appId: appId,
                            applUrl: response.data.tenantAppl.applicationUrl,
                            apiClientId: response.data.tenantAppl.apiClientId,
                            apiSecretId: response.data.tenantAppl.apiSecretId,
                            key: response.data.tenantAppl.key,
                            loading: false
                        })
                    })
                    .catch(async error => {
                        if (error.response.data.statusCode === 401) {
                            this.setState({ logouttoast: true })
                            await this.sleep(5000);
                            this.props.history.push("/logout");
                        }
                        this.setState({ errorMsg: 'Error fetching data from server - ' + error.response.data.message, loading: false })
                        // window.setTimeout(() => { this.setState({ errorMsg: '' }) }, 5000)
                    })
            });
        }
    }
    listApps() {
        axios.get('list-application/', { headers: getHeaders(this.props.token) })
            // API.get('list-application/')
            .then(response => {
                this.setState({ applications: response.data.tenantAppList })
            })
            .catch(async error => {
                if (error.response.data.statusCode === 401) {
                    this.setState({ logouttoast: true })
                    await this.sleep(5000);
                    this.props.history.push("/logout");
                }
                this.setState({ errorMsg: 'Error listing applications' })
            })
    }
    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    render() {
        const { sucMsg, errorMsg, tenantId, tenantAppId, tenantName, loading, connecting } = this.state
        let pageTitle, buttonTitle, Readonlyflag
        if (tenantAppId !== '' && tenantAppId !== undefined) {
            pageTitle = 'Manage Application';
            buttonTitle = 'Update';
            Readonlyflag = true;
        } else {
            pageTitle = 'Configure Application';
            buttonTitle = 'Create';
            Readonlyflag = false;
        }
        let tapplications = this.state.applications;
        let optionItems = tapplications.map((tapplication) =>
            <option key={tapplication.applId} value={tapplication.applId}>{tapplication.applName}</option>
        );
        
        return (
            <div id="container">
                <Header />
                <div id="wraps">
                    <Leftsidebar tenantId={tenantId} />
                    <div id="contents">
                        <LogoutMessage logoutToast={this.state.logouttoast} />
                        <div className="container topmargin">
                            <h5>{pageTitle}</h5>
                            {errorMsg !== '' && <div className="erroralert" >{errorMsg}</div>}
                            {sucMsg !== '' && <div className="successtext">{sucMsg}</div>}
                            {loading ? <LoadingSpinner /> :
                                <form>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <label>Tenant Name:</label>
                                        </div>
                                        <div className="col-md-4">
                                            <label>{tenantName}</label>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <label>Application:</label>
                                        </div>
                                        <div className="col-md-6">
                                            <select disabled={Readonlyflag} id="applId" value={this.state.applId} onChange={this.ddchangeHandler.bind(this)}>
                                                <option value="select">Select</option>
                                                {optionItems}
                                            </select>
                                            <div className="errortext">{this.state.AppNameError}</div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <label>Application URL:</label>
                                        </div>
                                        <div className="col-md-6">
                                            <input className="form-control" type="text" id="applUrl" name="applUrl" onChange={this.changeHandler} value={this.state.applUrl} />
                                            <div className="errortext">{this.state.applUrlError}</div>
                                        </div>
                                    </div>
                                    {/* <div className="row">
                                    <div className="col-md-3">
                                        <label>Application ID:</label>
                                    </div>
                                    <div className="col-md-6">
                                        <input className="form-control" type="text" id="applicationId" name="applicationId" onChange={this.changeHandler} value={this.state.applicationId} />
                                        <div className="errortext">{this.state.applicationIdError}</div>
                                    </div>
                                </div> */}
                                    <div className="row">
                                        <div className="col-md-3">
                                            <label>SCIM Token:</label>
                                        </div>
                                        <div className="col-md-6">
                                            <input readOnly className="form-control" type="text" id="key" name="key" onChange={this.changeHandler} value={this.state.key} />
                                        </div>
                                        <div className="col-md-3">
                                            <button type='submit' onClick={this.generateKeyHandler} className="btn btn-primary">Generate Token</button>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <label>URL for SCIM endpoint:</label>
                                        </div>
                                        <div className="col-md-6">
                                            <label>{this.state.scimEndPoint}</label>
                                        </div>
                                    </div>
                                    {this.state.applicationselected && <div className="row">
                                        <div className="col-md-12">
                                            <h6>PROVISIONING DETAILS</h6>
                                        </div>
                                    </div>}
                                    {(this.state.applicationselected && this.state.clientIdLabel !== '' && this.state.clientIdLabel !== undefined) && <div className="row">
                                        <div className="col-md-3">
                                            <label>{this.state.clientIdLabel}</label>
                                        </div>
                                        <div className="col-md-6">
                                            <input className="form-control" type="text" id="apiClientId" name="apiClientId" onChange={this.changeHandler} value={this.state.apiClientId} />
                                            <div className="errortext">{this.state.apiClientIdError}</div>
                                        </div>
                                    </div>}
                                    {this.state.applicationselected && <div className="row">
                                        <div className="col-md-3">
                                            <label>{this.state.secretIdLabel}</label>
                                        </div>
                                        <div className="col-md-6">
                                            <input className="form-control" type="text" id="apiSecretId" name="apiSecretId" onChange={this.changeHandler} value={this.state.apiSecretId} />
                                            <div className="errortext">{this.state.apiSecretIdError}</div>
                                        </div>
                                    </div>}
                                    <div className="row">
                                        <div className="col-md-3">
                                        </div>
                                        <div className="col-md-6">
                                            {connecting === false && <button type='submit' onClick={this.submitHandler} className="btn btn-primary">{buttonTitle}</button>}
                                            {connecting && <button className="btn btn-primary" variant="primary" disabled>
                                                <Spinner
                                                    as="span"
                                                    animation="grow"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />Connecting...</button>}
                                        </div>
                                    </div>
                                </form>}
                            {/* <Username username={username} /> */}
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}
// const Username = props => <div>{props.username}</div>;
const mapstateToProps = state => {
    return {
        token: state.myLogin.token,
        tenantAppCreatedBy: state.myLogin.username
    }
}
export default connect(
    mapstateToProps)
    (ApplicationAdd)
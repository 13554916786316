import {
  SAVE_USERNAME_REQUESTED,
  SAVE_TOKEN_REQUESTED,
  USER_FETCH_FAILED,
  USER_FETCH_SUCCEEDED
} from '../../Constants/Type';

const INITIAL_STATE = {
  username: ''
};
const myLogin = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SAVE_USERNAME_REQUESTED:
      return {
        ...state,
        username: action.payload
      };
    case SAVE_TOKEN_REQUESTED:
      return {
        ...state,
        token: action.payload
      };
    case USER_FETCH_SUCCEEDED:
      return {
        ...state,
        users: action.payload
      };
    case USER_FETCH_FAILED:
    default:
      return state;
  }
};

export default myLogin;

export const getHeaders = (token,accessToken) => {
    return {      
      'Content-Type': 'application/json;',
      'Accept' : 'text/html, application/xml;q=0.9, */*;q=0.8',
      'x-api-key': token,
      'Authorization': "Bearer "+accessToken
    };
  };

  export const getSSOHeaders = (token,userPrincipalName) => {
    return {      
      'Content-Type': 'application/json;',
      'Accept' : 'text/html, application/xml;q=0.9, */*;q=0.8',
      'Authorization': "Bearer "+token,
      'UserPrincipalName': userPrincipalName
    };
  };
  

import React, { useState } from 'react';
import Toast from 'react-bootstrap/Toast';
const styles = {
    position: 'absolute', top: 0, right: 0,color: 'red',
};
function LogoutToastMessage(props) {
    const [showA, setShowA] = useState(true);
    const toggleShowA = () => setShowA(!showA);
    if (props.logoutToast) {
        return (
            <div className="row">
                <div className="col-md-7">
                    <Toast show={showA} onClose={toggleShowA} style={styles} delay={5000} >
                        <Toast.Header>                            
                            <strong className="mr-auto errortext">Login Required</strong>
                        </Toast.Header>
                        <Toast.Body className="toastmessages">Session expired...! Login again.</Toast.Body>
                    </Toast>
                </div>
            </div>
        );
    }
    else {
        return null;
    }

}

export default class LogoutMessage extends React.Component {

    render() {
        return (
            <LogoutToastMessage logoutToast={this.props.logoutToast} />
        );
    }
}
import React from 'react';
import './Footer.css';

const Footer = () => {
  const today = new Date();
  return (
    <div className="footer-container">
      <div className="copyright-wrapper">{`Ⓒ${today.getFullYear()}`} All Rights Reserved</div>
    </div>
  );
};

export default Footer;

import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import { userLogout } from './LogoutActions';

class Logout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticate: false,
      isSignedUp: false,
      username: undefined
    }
  }
  componentDidMount(props) {
    this.props.userLogout();
  }
  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  render() {
    return <Redirect to={{ pathname: "/admin-login" }} />;
  }
}
const mapDispatchToProps = dispatch => {
  return {
    userLogout: () => dispatch(userLogout())
  }
}
export default connect(
  null,
  mapDispatchToProps)
  (Logout)
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './RootComponent.css'
import ProtectedRoute from './ProtectedRoute';
import Login from '../Pages/Login/Login';
import TenantList from '../Pages/TenantList/TenantList';
import TenantAdd from '../Pages/TenantAdd/TenantAdd';
import TenantUserAdd from '../Pages/TenantUserAdd/TenantUserAdd';
import TenantUserList from '../Pages/TenantUserList/TenantUserList';
import ApplicationList from '../Pages/ApplicationList/ApplicationList';
import ApplicationAdd from '../Pages/ApplicationAdd/ApplicationAdd';
import AdministratorAdd from '../Pages/AdministratorAdd/AdministratorAdd';
import AdministratorList from '../Pages/AdministratorList/AdministratorList';
import ChangePassword from '../Pages/ChangePassword/ChangePassword';
import SetPassword from '../Pages/SetPassword/SetPassword';
import Config from '../Pages/Config/Config';
import Logout from '../Pages/Logout/Logout';
import ForgotPassword from '../Pages/ForgotPassword/ForgotPassword';
import AdminPasswordReset from '../Pages/AdminPasswordReset/AdminPasswordReset';
import AuthTenantProfile from '../AuthPages/AuthTenantProfile/AuthTenantProfile';
import AuthAppList from '../AuthPages/AuthAppList/AuthAppList';
import AuthAppAdd from '../AuthPages/AuthAppAdd/AuthAppAdd';
import AuthMemberList from '../AuthPages/AuthMemberList/AuthMemberList';
import AuthUserList from '../AuthPages/AuthUserList/AuthUserList';
import AuthUserAdd from '../AuthPages/AuthUserAdd/AuthUserAdd';
import Auth0ProviderWithHistory from "../SSOPages/Auth0/views/auth0-provider-with-history";
import ProtectedRouteAuth from "../SSOPages/Auth0/views/protected-route";
import Home from '../SSOPages/Auth0/views/Home';

const RootComponent = () => {
  return (
    <div className="route-wrapper">
      <Router>
        <Switch>
          <Route exact path="/admin-login" component={Login} />
          <ProtectedRoute path="/admin-add/:userId?" component={AdministratorAdd} />
          <ProtectedRoute path="/admin-list" component={AdministratorList} />
          <ProtectedRoute path="/tenant-list" component={TenantList} />
          <ProtectedRoute path="/tenant-add/:tenantId?" component={TenantAdd} />
          <ProtectedRoute path="/tenant-user-add/:tenantId?/:tenantUserId?" component={TenantUserAdd} />
          <ProtectedRoute path="/tenant-user-list/:tenantId?" component={TenantUserList} />
          <ProtectedRoute path="/application-list/:tenantId?" component={ApplicationList} />
          <ProtectedRoute path="/application-add/:tenantId?/:tenantAppId?" component={ApplicationAdd} />
          <ProtectedRoute path="/admin-change-password" component={ChangePassword} />
          <ProtectedRoute path="/config-details" component={Config} />
          <Route path="/admin-forgot-password" component={ForgotPassword} />
          <Route path="/admin-invitation/:key?" component={SetPassword} />
          <Route path="/admin-password-reset/:key?" component={AdminPasswordReset} />
          <Route exact path="/logout" component={Logout} />
          {/* <Route path="*" component={() => "404 NOT FOUND"} /> */}
        </Switch>
        <Auth0ProviderWithHistory>
          <Switch>            
            <ProtectedRouteAuth path="/tenant-profile" component={AuthTenantProfile} />
            <ProtectedRouteAuth path="/app-list" component={AuthAppList} />
            <ProtectedRouteAuth path="/app-add" component={AuthAppAdd} />
            <ProtectedRouteAuth path="/member-list" component={AuthMemberList} />
            <ProtectedRouteAuth path="/user-add" component={AuthUserAdd} />
            <ProtectedRouteAuth path="/user-list" component={AuthUserList} />
            <Route exact path="/" component={Home} />
          </Switch>
        </Auth0ProviderWithHistory>
      </Router>

    </div>
  );
};
export default RootComponent;

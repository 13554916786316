class Auth {
    constructor() {
        this.isAuthenticate = false;        
    }
    login(cb) {
        this.isAuthenticate = true;        
        cb()
    }
    logout(cb) {
        this.isAuthenticate = false;        
    }
    isAuthenticated(){
        return this.isAuthenticate;        
    }
}
export default new Auth()
import React from 'react';
import axios from 'axios';
// import { Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Header from '../../Molecules/Header/Header';
import Footer from '../../Molecules/Footer/Footer';
import './Login.css';
import { saveUsername, saveToken } from './LoginActions';
import { saveIsAuthenticated } from '../../Router/ProtectedRouteActions';
// import { savedIdpAccessToken, saveIdpUsername } from '../../SSOPages/Auth0/views/HomeActions';
import auth from '../../Router/Auth';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      isAuthenticate: false,
      isAuthenticatedError: '',
      // isSignedUp: false,
      usernameError: ''
    }
  }
  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }
  validate = () => {
    let isError = false;
    const errors = {
      usernameError: '',
      isAuthenticatedError: ''
    };

    if (this.state.username.length === 0 || this.state.password.length === 0) {
      isError = true;
      errors.usernameError = "Give your username and password to login";
    }

    this.setState({
      ...this.state,
      ...errors
    });

    return isError;
  };
  submitHandler = (e) => {
    e.preventDefault()
    const err = this.validate();
    if (!err) {
      this.setState({
        usernameError: ""
      });
      sessionStorage.removeItem('persist:root')
      axios.post('login-superuser', this.state)
        .then(response => {
          if (response.status === 200) {            
            this.props.saveUsername(this.state.username);
            this.props.saveToken(response.data.token);
            this.props.saveIsAuthenticated(true);
            // this.props.saveIdpUsername('');
            // this.props.savedIdpAccessToken('');
            // this.setState({ isSignedUp: true });

            auth.login(() => {
              if (this.props.token !== '') {
                this.props.history.push("/admin-list");
              }
            })
          }
        })
        .catch(error => {
          this.setState({ isAuthenticate: false, isAuthenticatedError: error.response.data.message });
          })
    }
  }
  // componentDidMount() {

  // }
  render() {
    // if (this.state.isSignedUp) {
    //   return <Redirect to={{ pathname: "/admin-list" }} />;
    // }
    const { username, password } = this.state
    return (
      <div id="logincontainer">
        <Header />
        <div className="login-page">
          <div className="form">
            <h5>Admin Login</h5>
            <form className="login-form" >
              {this.state.isAuthenticatedError !== '' && <div className="errortext">{this.state.isAuthenticatedError}</div>}
              {this.state.usernameError !== '' &&
                <div>
                  <div className="errortext">{this.state.usernameError}</div>
                  <input type="text" className="FocusBox" placeholder="Username" name="username" value={username} onChange={this.changeHandler} />
                  <input type="password" className="FocusBox" placeholder="Password" name="password" value={password} onChange={this.changeHandler} />
                </div>}
              {this.state.usernameError === '' &&
                <div>
                  <input type="text" placeholder="Username" name="username" value={username} onChange={this.changeHandler} />
                  <input type="password" placeholder="Password" name="password" value={password} onChange={this.changeHandler} />
                </div>}
              <button onClick={this.submitHandler}>login</button>
              <div className="message"><Link to="/admin-forgot-password">forgot password?</Link></div>
            </form>
          </div>
        </div>
        <Footer />
      </div>);
  }
}
const mapstateToProps = state => {
  return {
    username: state.myLogin.username,
    token: state.myLogin.token
  }
}

const mapDispatchToProps = dispatch => {
  return {
    saveUsername: text => dispatch(saveUsername(text)),
    saveToken: text => dispatch(saveToken(text)),
    saveIsAuthenticated: bool => dispatch(saveIsAuthenticated(bool)),
    // saveIdpUsername: text => dispatch(saveIdpUsername(text)),
    // savedIdpAccessToken: text => dispatch(savedIdpAccessToken(text)),
  }
}
export default connect(
  mapstateToProps,
  mapDispatchToProps)
  (Login)
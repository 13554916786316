import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { persistor, store } from './store/configureStore';
import RootComponent from './components/Router/RootComponent';
import 'bootstrap/dist/css/bootstrap.min.css';
// fontawesome
import initFontAwesome from "./components/SSOPages/Auth0/views/initFontAwesome";
initFontAwesome();

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <RootComponent />
      </PersistGate>
    </Provider>
  );
};

export default App;

import React from 'react';
import { connect } from 'react-redux';
import DataTable from 'react-data-table-component';
import HeaderAuth from '../../Molecules/Header/HeaderAuth';
import Footer from '../../Molecules/Footer/Footer';
import API from '../../Api/ApiPath';
import { getSSOHeaders } from '../../Api/ClientUtils';
import { userLogout } from '../../Pages/Logout/LogoutActions';
import LogoutMessage from '../../Molecules/Toaster/LogoutMessage';
import strings from '../../../lang';
import { withAuth0 } from "@auth0/auth0-react";
import Modal from '../../Molecules/Modal/Modal';
import FilterComponent from "./FilterComponent";
import Axios from 'axios';
class AuthMemberList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            posts: [],
            tenantName: '',
            errorMsg: '',
            optionvalue: 'select',
            tenantId: '',
            applUrl: '',
            pending: true,
            BearerToken: '',
            sucMsg: '',
            logouttoast: false,
            filterText: '',
            resetPaginationToggle: false,
            scimToken: '',
        }
    }
    async componentDidMount() {
        try {
            const { getAccessTokenSilently } = this.props.auth0;
            const token = await getAccessTokenSilently();
            this.setState({ BearerToken: token })
        }
        catch (err) {
            this.setState({ errorMsg: 'Error - ' + JSON.stringify(err) })
            // window.setTimeout(()=>{this.setState({errorMsg:''})},5000)
            // if (err.errorCode === "consent_required" || err.errorCode === "interaction_required" || err.errorCode === "login_required") {
            this.setState({ logouttoast: true })
            this.logout()
        }
        // }
        this.listApps()
        this.listAppName()
    }
    setFilterText(val) {
        this.setState({ filterText: val })
    }
    handleClear = () => {
        if (this.state.filterText) {
            this.setState({
                resetPaginationToggle: !this.state.resetPaginationToggle,
                filterText: ""
            });
        }
    }
    listApps() {
        API.get('list-user-details/' + this.props.saveOperaAppId, { headers: getSSOHeaders(this.state.BearerToken, this.props.userPrincipalName) })
            // API.get('list-user-details/0', { headers: getSSOHeaders(this.state.BearerToken) })        
            .then(response => {
                this.setState({
                    posts: response.data.userList,
                    pending: false
                })
            })
            .catch(error => {
                this.setState({
                    posts: '',
                    pending: false
                })
            })
    }
    listAppName() {
        const tenantAppId = this.props.saveOperaAppId
        API.post('view-tenant-application', { tenantAppId }, { headers: getSSOHeaders(this.state.BearerToken, this.props.userPrincipalName) })
            .then(response => {
                this.setState({
                    applUrl: response.data.tenantAppl.applicationUrl,
                    scimToken: response.data.tenantAppl.key
                })
            })
            .catch(error => {
                this.setState({ errorMsg: 'Error - ' + error.response.data.message })
                // window.setTimeout(()=>{this.setState({errorMsg:''})},5000)
            })
    }
    deleteHandler() {
        const userId = this.state.DeleteID.toString();
        var scimEndPoint;
        if (process.env.REACT_APP_BASEURL === '' || process.env.REACT_APP_BASEURL === undefined) {
            scimEndPoint = window.location.protocol + '//' + window.location.hostname + '/serve/scim/v2';
        }
        else {
            var href = process.env.REACT_APP_BASEURL || 'http://localhost:8080/api/';
            var strArray = href.split(":");
            scimEndPoint = strArray[0] + ":" + strArray[1] + '/scim/v2';
        }
        Axios.delete(scimEndPoint+'/Users/' + userId, { headers: { 'Authorization': 'Bearer ' + this.state.scimToken } })
        // Axios.delete('http://localhost:8080/scim/v2/Users/' + userId, { headers: { 'Authorization': 'Bearer ' + this.state.scimToken } })
            .then(response => {
                document.getElementById(`deleteModal`).style.display = 'none';
                this.setState({ sucMsg: 'Successfully deleted user.',errorMsg:'' })
                this.listApps()
            })
            .catch(async error => {
                document.getElementById(`deleteModal`).style.display = 'none';
                if (error.response) {
                if (error.response.data.statusCode === 401) {
                    this.setState({ logouttoast: true })
                    await this.sleep(5000);
                    this.props.history.push("/logout");
                }}
                this.setState({ errorMsg: 'Error while deleting the user.',sucMsg:'' })
                this.listApps()
            })
    }
    render() {
        const { posts, errorMsg, pending, sucMsg, applUrl } = this.state
        const columns = [
            {
                name: 'ID',
                selector: 'id',
                sortable: true,
                width: '280px'
            },
            {
                name: 'Name',
                selector: 'displayName',
                sortable: true,
                width: '180px'
            },
            {
                name: 'Username',
                selector: 'userName',
                sortable: true,
                width: '180px'
            },
            {
                name: 'Group',
                selector: 'userGroup',
                sortable: true
            },
            {
                name: 'Create Status',
                selector: 'createStatus',
                sortable: true,
                width: '90px'
            },
            {
                name: 'Created',
                selector: 'createdTime',
                sortable: true,
                width: '100px'
            },
            {
                name: 'Delete',
                selector: 'id',
                cell: (row) => <div
                    className="center"
                    onClick={e => {
                        e.stopPropagation();
                        document.getElementById(`deleteModal`).style.display = 'block';
                        this.setState({ DeleteID: row.id })
                    }}
                    role="presentation"
                >
                    {row.createStatus === 'ACTIVE' && <img
                        src={require('../../../assets/images/delete.svg')}
                        alt={strings.delete}
                        className="delete-img"
                    />}
                </div>,
                ignoreRowClick: true,
                allowOverflow: true,
                width: '120px'
            }

        ];
        const filteredItems = posts.filter(
            item =>
                JSON.stringify(item)
                    .toLowerCase()
                    .indexOf(this.state.filterText.toLowerCase()) !== -1
        );
        return (
            <div id="container">
                <HeaderAuth />
                <LogoutMessage logoutToast={this.state.logouttoast} />
                <Modal
                    headerText={strings.confirmDelete}
                    uniqueModalName="deleteModal"
                    message={strings.requestDeleteMemberConfirmMessage}
                    minusText={strings.cancel}
                    minusAction={() => {
                        document.getElementById('deleteModal').style.display = 'none';
                    }}
                    plusText={strings.confirm}
                    plusAction={() => {
                        this.deleteHandler(this.state.DeleteID);
                    }}
                />
                <div className="container topmargin">
                    <div className="row">
                        <div className="col-md-10"><h4>Application Members</h4></div>
                    </div>
                    <div className="row">
                        <div className="col-md-8">
                            <label>Application URL: <i>{applUrl}</i></label>
                            <img
                                src={require('../../../assets/images/refresh.png')}
                                alt={strings.refresh}
                                className="refreshimg"
                                onClick={e => { this.listApps() }}
                            /></div>
                        <div className="d-flex col-md-4">
                            <FilterComponent
                                onFilter={e => this.setFilterText(e.target.value)}
                                onClear={this.handleClear}
                                filterText={this.state.filterText}
                            />
                        </div>
                    </div>
                    {errorMsg !== '' && <div className="erroralert" >{errorMsg}</div>}
                    {sucMsg !== '' && <div className="successtext">{sucMsg}</div>}

                    <DataTable
                        columns={columns}
                        // data={posts}
                        data={filteredItems}
                        pagination={true}
                        highlightOnHover={true}
                        dense={true}
                        responsive={true}
                        progressPending={pending}
                        noHeader={true}
                    />
                </div>
                <Footer />
            </div>
        );
    }
    setErrorMessage(message, debug) {
        this.setState({
            error: { message: message, debug: debug }
        });
    }
    async logout(props) {
        await this.sleep(5000);
        props.userLogout();
    }
    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
}
const mapstateToProps = state => {
    return {
        saveOperaAppId: state.AppId.saveOperaAppId,
        userPrincipalName: state.userTenantId.saveIdpUsername
    }
}
const mapDispatchToProps = dispatch => {
    return {
        userLogout: () => dispatch(userLogout())
    }
}
export default withAuth0(connect(
    mapstateToProps,
    mapDispatchToProps)
    (AuthMemberList))


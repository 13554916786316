import React from 'react';
import Footer from '../../Molecules/Footer/Footer';
import API from '../../Api/ApiPath';
import Header from '../../Molecules/Header/Header';
import Spinner from 'react-bootstrap/Spinner';
class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            emailAddress: '',
            sucMsg: '',
            errorMsg: '',
            loading: false,
        }
    }
    validate = () => {
        let isError = false;
        const errors = {
            usernameError: "",
            errorMsg: '',
            sucMsg: '',
        };
        if (this.state.emailAddress === '') {
            isError = true;
            errors.usernameError = "Username is required";
        }

        this.setState({
            ...this.state,
            ...errors
        });
        return isError;
    };
    changeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    submitHandler = (e) => {
        e.preventDefault()
        const err = this.validate();
        if (!err) {
            this.setState({
                usernameError: "",
                sucMsg:'',
                errorMsg:''
            });            
            this.setState({ loading: true }, () => {
            API.post('forgot-password', this.state)
                .then(response => {
                    this.setState({
                        sucMsg: 'Password reset link has been sent to your email successfully.',
                        loading: false
                    })
                    // window.setTimeout(() => { this.setState({ sucMsg: '' }) }, 5000)
                })
                .catch(error => {
                    this.setState({
                        errorMsg: 'Error - ' + error.response.data.message,
                        loading: false
                    })
                    // window.setTimeout(() => { this.setState({ errorMsg: '' }) }, 5000)
                })
            });
        }
        this.setState({
            // emailAddress: ''
        });
    }
    componentDidMount() {
        var href = window.location.href;
        var pathname = window.location.pathname
        var url = href.replace(pathname, '');
        this.setState({
            url: url
        })
    }
    render() {
        const { errorMsg, sucMsg, loading } = this.state
        let pageTitle, buttonTitle
        pageTitle = 'Forgot Your Password';
        buttonTitle = 'Send';
        return (
            <div id="container">
                <Header />
                <div className="container topmargin">
                    <h5>{pageTitle}</h5>
                    {errorMsg !== '' && <div className="erroralert" >{errorMsg}</div>}
                    {sucMsg !== '' && <div className="successtext">{sucMsg}</div>}
                    <form>
                        <div className="row">
                            <div className="col-md-3">
                                <label>Type your User ID:</label>
                            </div>
                            <div className="col-md-4">
                                <input className="form-control" type="text" id="emailAddress" name="emailAddress" onChange={this.changeHandler} value={this.state.emailAddress} />
                                <div className="errortext">{this.state.usernameError}</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">
                            </div>
                            <div className="col-md-9">
                                {loading === false && <button type='submit' onClick={this.submitHandler} className="btn btn-primary">{buttonTitle}</button>}
                                {loading && <button className="btn btn-primary" variant="primary" disabled>
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />Sending...</button>}
                            </div>
                        </div>
                    </form>

                </div>
                <Footer />
            </div>
        );
    }
}
export default ForgotPassword;
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Header from '../../Molecules/Header/Header';
import Footer from '../../Molecules/Footer/Footer';
import Button from '../../Atoms/Button/Button';
import axios from 'axios';
import { getHeaders } from '../../Api/ClientUtils';
// import API from '../../Api/ApiPath';
import strings from '../../../lang';
import Modal from '../../Molecules/Modal/Modal';
import LogoutMessage from '../../Molecules/Toaster/LogoutMessage';
import Leftsidebar from '../../Molecules/Leftsidebar/Leftsidebar';
class TenantUserList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            posts: [],
            sucMsg: '',
            errorMsg: '',
            tenantId: '',
            tenantName: '',
            pending: true,
            logouttoast: false,
        }
        this.deleteHandler = this.deleteHandler.bind(this)
    }
    componentDidMount() {
        this.listUsers()
        this.listTenants()
    }
    listUsers() {
        const tenantId = this.props.match.params.tenantId
        this.setState({ tenantId: tenantId })
        axios.get('list-tenant-user/' + this.props.match.params.tenantId, { headers: getHeaders(this.props.token) })  
        // API.get('list-tenant-user/' + this.props.match.params.tenantId)
            .then(response => {
                this.setState({ posts: response.data.tenantList, pending: false })
            })
            .catch(async error => {
                if (error.response.data.statusCode === 401) {
                    this.setState({ logouttoast: true })
                    await this.sleep(5000);
                    this.props.history.push("/logout");
                }
                this.setState({ errorMsg: 'Error - ' + error.response.data.message, pending: false })
                // window.setTimeout(() => { this.setState({ errorMsg: '' }) }, 5000)
            })
    }
    listTenants() {
        const tenantId = this.props.match.params.tenantId
        axios.post('view-tenant' ,{ tenantId }, { headers: getHeaders(this.props.token) })  
        // API.post('view-tenant', { tenantId })
            .then(response => {
                this.setState({ tenantName: response.data.tenant.tenantName, tenantId: response.data.tenant.tenantId })
            })
            .catch(async error => {
                if (error.response.data.statusCode === 401) {
                    this.setState({ logouttoast: true })
                    await this.sleep(5000);
                    this.props.history.push("/logout");
                }
            })
    }
    deleteHandler() {
        const tenantUserId = this.state.DeleteID.toString()
        axios.post('delete-tenant-user' ,{ tenantUserId }, { headers: getHeaders(this.props.token) })  
        // API.post('delete-tenant-user', { tenantUserId })
            .then(response => {
                document.getElementById(`deleteModal`).style.display = 'none';
                this.setState({ sucMsg: 'Successfully deleted the user' })
                // window.setTimeout(() => { this.setState({ sucMsg: '' }) }, 5000)
                this.listUsers()
            })
            .catch(async error => {
                if (error.response.data.statusCode === 401) {
                    this.setState({ logouttoast: true })
                    await this.sleep(5000);
                    this.props.history.push("/logout");
                }
                this.setState({ errorMsg: 'Error - ' + error.response.data.message })
                // window.setTimeout(() => { this.setState({ errorMsg: '' }) }, 5000)
            })
    }
    userEnableDisable(user_Id, user_status) {
        const tenantUserId = user_Id.toString()
        const tenantUserStatus = user_status
        axios.post('update-status-tenant-user' ,{ tenantUserId, tenantUserStatus  }, { headers: getHeaders(this.props.token) })  
        // API.post('update-status-tenant-user', { tenantUserId, tenantUserStatus })
            .then(response => {
                this.setState({ sucMsg: 'User status changed successfully.' })
                // window.setTimeout(() => { this.setState({ sucMsg: '' }) }, 5000)
                this.listUsers()
            })
            .catch(async error => {
                if (error.response.data.statusCode === 401) {
                    this.setState({ logouttoast: true })
                    await this.sleep(5000);
                    this.props.history.push("/logout");
                }
                this.setState({ errorMsg: 'Error - ' + error.response.data.message })
                // window.setTimeout(() => { this.setState({ errorMsg: '' }) }, 5000)
            })

    }
    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    render() {
        const { posts, errorMsg, tenantName, sucMsg, pending } = this.state
        const columns = [

            {
                name: 'User ID',
                selector: 'userEmail',
                sortable: true
            },
            {
                name: 'Status',
                selector: 'tenantUserStatus',
                sortable: true,
                width: '120px'
            },
            {
                name: 'User Role',
                selector: 'tenantUserRole',
                sortable: true,
                width: '120px'
            },
            {
                name: 'Last Login',
                selector: 'tenantUserLastLogin',
                sortable: true,
                width: '120px'
            },
            {
                name: 'Manage',
                selector: 'tenantUserId',
                cell: row => <div>{row.tenantUserRole !== "ADMIN" && <Link to={"/tenant-user-add/" + row.tenantId + "/" + row.tenantUserId}><Button>Manage</Button></Link>}</div>,
                ignoreRowClick: true,
                allowOverflow: true,
                width: '120px'
            },
            {
                name: 'Enable/Disable',
                selector: 'tenantUserId',
                cell: (row) => <div>{row.tenantUserStatus === 'ACTIVE' && row.tenantUserRole !== "ADMIN" && <div onClick={(e) => {
                    e.stopPropagation();
                    this.userEnableDisable(row.tenantUserId, 0)
                }} ><Button>Disable</Button></div>}
                    {row.tenantUserStatus === 'INACTIVE' && row.tenantUserRole !== "ADMIN" && <div onClick={(e) => {
                        e.stopPropagation();
                        this.userEnableDisable(row.tenantUserId, 1)
                    }} ><Button>Enable</Button></div>}</div>,
                ignoreRowClick: true,
                allowOverflow: true,
                width: '120px'
            },
            {
                name: 'Delete',
                selector: 'tenantUserId',
                cell: (row) => <div
                    className="center"
                    onClick={e => {
                        e.stopPropagation();
                        document.getElementById(`deleteModal`).style.display = 'block';
                        this.setState({ DeleteID: row.tenantUserId })

                    }}
                    role="presentation"
                >
                    {row.tenantUserRole !== "ADMIN" && <img
                        src={require('../../../assets/images/delete.svg')}
                        alt={strings.delete}
                        className="delete-img"
                    />}
                </div>,
                ignoreRowClick: true,
                allowOverflow: true,
                width: '120px'
            }
        ];
        return (
            <div id="container">
                <Header />
                <div id="wraps">
                <Leftsidebar tenantId={this.state.tenantId} />
                <div id="contents">
                <LogoutMessage logoutToast={this.state.logouttoast} />                
                <div className="container topmargin">                   
                    <div className="row">
                        <div className="col-md-10"><h5>Users of <i>{tenantName}</i></h5></div>
                        <div className="col-md-2"> <Link to={"/tenant-user-add/" + this.state.tenantId}><Button>New User</Button></Link></div>
                    </div>
                    {errorMsg !== '' && <div className="erroralert" >{errorMsg}</div>}
                    {sucMsg !== '' && <div className="successtext">{sucMsg}</div>}
                    <DataTable
                        columns={columns}
                        data={posts}
                        pagination={true}
                        highlightOnHover={true}
                        dense={true}
                        responsive={true}
                        progressPending={pending}
                        noHeader={true}
                    />
                </div>
                <Modal
                    headerText={strings.confirmDelete}
                    uniqueModalName="deleteModal"
                    message={strings.requestDeleteUserConfirmMessage}
                    minusText={strings.cancel}
                    minusAction={() => {
                        document.getElementById('deleteModal').style.display = 'none';
                    }}
                    plusText={strings.confirm}
                    plusAction={() => {
                        this.deleteHandler(this.state.DeleteID);
                    }}
                />
                </div>
                </div>
                <Footer />
            </div>
        );
    }
}
const mapstateToProps = state => {
    return {
        token: state.myLogin.token
    }
}
export default connect(
    mapstateToProps)
    (TenantUserList);

import React from 'react';
import './Button.css';

const Button = ({ children, className, action }) => {
  return (
    <button
      type="button"
      className={`primary-btn ripple ${className}`}
      onClick={action}
    >
      {children}
    </button>
  );
};

export default Button;

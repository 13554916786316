import React from 'react';
import Header from '../../Molecules/Header/Header';
import Footer from '../../Molecules/Footer/Footer';
// import API from '../../Api/ApiPath';
import { connect } from 'react-redux';
import axios from 'axios';
import { getHeaders } from '../../Api/ClientUtils';
import LogoutMessage from '../../Molecules/Toaster/LogoutMessage';
import LoadingSpinner from '../../Molecules/LoadingSpinner/LoadingSpinner';
import Spinner from 'react-bootstrap/Spinner';
class AdministratorAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            adminName: '',
            adminusername: '',
            password: '',
            contactNo: '',
            username: '',
            posts: [],
            logouttoast: false,
            errorMsg: '',
            loading: false,
            connecting: false,
            url:''
        }
    }
    validate = () => {
        let isError = false;
        const errors = {
            adminNameError: "",
            usernameError: "",
            passwordError: "",
            contactNoError: "",
            errorMsg: '',
            sucMsg: '',
        };
        if (this.state.adminName === '') {
            isError = true;
            errors.adminNameError = "Admin name is required";
        }
        if (this.state.username === '' || this.state.username.length < 5) {
            isError = true;
            errors.usernameError = "Username required and should be atleast 5 characters long";
        }
        if (this.state.password.length < 4 && this.state.userId === '') {
            isError = true;
            errors.passwordError = "Password required and should be atleast 4 characters long";
        }
        if (this.state.contactNo === '') {
            isError = true;
            errors.contactNoError = "Contact Number is required";
        }
        this.setState({
            ...this.state,
            ...errors
        });
        return isError;
    };
    changeHandler = (e) => {
        console.log(e)
        this.setState({
            [e.target.name]: e.target.value,
            // username: this.state.username
        })
    }
    submitHandler = (e) => {
        e.preventDefault()
        const err = this.validate();
        if (!err) {
            this.setState({
                adminNameError: "",
                usernameError: "",
                passwordError: "",
                contactNoError: "",
                errorMsg:''
            });            
            const userId = this.props.match.params.userId
            if (userId !== '' && userId !== undefined) {
                this.setState({ connecting: true }, () => {
                    axios.post('update-superuser', this.state, { headers: getHeaders(this.props.token) })
                        .then(response => {
                            this.props.history.push("/admin-list/");
                        })
                        .catch(async error => {
                            if (error.response.data.statusCode === 401) {
                                this.setState({ logouttoast: true })
                                await this.sleep(5000);
                                this.props.history.push("/logout");
                            }
                            this.setState({ errorMsg: 'Error while updating admin user - ' + error.response.data.message, connecting: false })
                            // window.setTimeout(() => { this.setState({ errorMsg: '' }) }, 5000)
                        })
                });
            } else {
                this.setState({ connecting: true }, () => {
                    axios.post('add-superuser', this.state, { headers: getHeaders(this.props.token) })
                        .then(response => {
                            this.props.history.push("/admin-list/");
                        })
                        .catch(async error => {
                            if (error.response.data.statusCode === 401) {
                                this.setState({ logouttoast: true })
                                await this.sleep(5000);
                                this.props.history.push("/logout");
                            }
                            this.setState({ errorMsg: 'Error while adding admin user - ' + error.response.data.message, connecting: false })
                            // window.setTimeout(() => { this.setState({ errorMsg: '' }) }, 5000)
                        })
                });
            }
        }
    }
    componentDidMount(_props) {
        const userId = this.props.match.params.userId
        var href = window.location.href;
        var pathname = window.location.pathname
        var url = href.replace(pathname, '');
        this.setState({
            userId: userId,
            url: url
        })
        if (userId !== '' && userId !== undefined) {
            this.setState({ loading: true }, () => {
                axios.post('view-superuser', { userId }, { headers: getHeaders(this.props.token) })
                    .then(response => {
                        this.setState({
                            adminName: response.data.loginUser.adminName,
                            username: response.data.loginUser.username,
                            // adminusername: response.data.loginUser.username,
                            contactNo: response.data.loginUser.contactNo,
                            loading: false
                        })
                    })
                    .catch(async error => {
                        if (error.response.data.statusCode === 401) {
                            this.setState({ logouttoast: true })
                            await this.sleep(5000);
                            this.props.history.push("/logout");
                        }
                        this.setState({ errorMsg: 'Error fetching data from server', loading: false })
                        // window.setTimeout(() => { this.setState({ errorMsg: '' }) }, 5000)
                    })
            });
        }
    }
    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    render() {
        const { userId, errorMsg, loading, connecting } = this.state
        let pageTitle, buttonTitle, buttonTitles
        if (userId !== '' && userId !== undefined) {
            pageTitle = 'Manage Admin User';
            buttonTitle = 'Update'; buttonTitles = 'Updating...';
            // hiderows = false;
        } else {
            pageTitle = 'Create Admin User';
            buttonTitle = 'Create'; buttonTitles = 'Creating...';
            // hiderows = true;
        }
        return (
            <div id="container">
                <Header />
                <LogoutMessage logoutToast={this.state.logouttoast} />
                <div className="container topmargin">
                    <h4>{pageTitle}</h4>
                    {errorMsg !== '' && <div className="erroralert" >{errorMsg}</div>}
                    {loading ? <LoadingSpinner /> : <form>
                        <div className="row">
                            <div className="col-md-2">
                                <label>Admin Name:</label>
                            </div>
                            <div className="col-md-4">
                                <input className="form-control" type="text" id="adminName" name="adminName" onChange={this.changeHandler} value={this.state.adminName} />
                                <div className="errortext">{this.state.adminNameError}</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2">
                                <label>Admin User ID:</label>
                            </div>
                            <div className="col-md-4">
                                <input className="form-control" type="text" id="username" name="username" onChange={this.changeHandler} value={this.state.username} />
                                <div className="errortext">{this.state.usernameError}</div>
                            </div>
                            <div className="col-md-4"><label>( <i>Please specify an Email Address</i> )</label></div>
                        </div>
                        {/* {hiderows &&<div className="row">
                            <div className="col-md-2">
                                <label>Password:</label>
                            </div>
                            <div className="col-md-4">
                                <input className="form-control" type="password" id="password" name="password" onChange={this.changeHandler} value={this.state.password} />
                                <div className="errortext">{this.state.passwordError}</div>
                            </div>
                        </div>} */}
                        <div className="row">
                            <div className="col-md-2">
                                <label>Contact Number:</label>
                            </div>
                            <div className="col-md-4">
                                <input className="form-control" type="text" id="contactNo" name="contactNo" onChange={this.changeHandler} value={this.state.contactNo} />
                                <div className="errortext">{this.state.contactNoError}</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2">
                            </div>
                            <div className="col-md-10">
                                {connecting === false && <button type='submit' onClick={this.submitHandler} className="btn btn-primary">{buttonTitle}</button>}
                                {connecting && <button className="btn btn-primary" variant="primary" disabled>
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />{buttonTitles}</button>}
                            </div>
                        </div>
                    </form>}

                </div>
                <Footer />
            </div>
        );
    }
}
// export default AdministratorAdd;

const mapstateToProps = state => {
    return {
        token: state.myLogin.token,
        // username:  state.myLogin.username
    }
}
export default connect(
    mapstateToProps)
    (AdministratorAdd)
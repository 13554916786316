import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import './Header.css';
class Header extends React.Component {
  render() {
    return (
      <div id="header">
        <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
        {this.props.username === '' &&<Navbar.Brand as={Link} to="/admin-login">SCIM Manager</Navbar.Brand>}
        {this.props.username !== '' &&<Navbar.Brand as={Link} to="/admin-list">SCIM Manager</Navbar.Brand>}  
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
          {this.props.username !== '' &&<Nav className="mr-auto">
              <Nav.Link as={Link} to="/admin-list">Administrator</Nav.Link>
              <Nav.Link as={Link} to="/tenant-list">Tenants</Nav.Link>
              <Nav.Link as={Link} to="/config-details">Settings</Nav.Link>
            </Nav>}
            {this.props.username === '' &&<Nav className="mr-auto"></Nav>}
            {this.props.username !== '' && <Nav>
              <Nav.Link as={Link} to="/admin-change-password">Change Password</Nav.Link>
              <Nav.Link as={Link} to="/logout">({this.props.username}) Logout</Nav.Link>
            </Nav>}
            {this.props.username === '' && <Nav>
              <Nav.Link as={Link} to="/admin-login">Login</Nav.Link>
            </Nav>}
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }
}
const mapstateToProps = state => {
  return {
    username: state.myLogin.username
  }
}
export default connect(
  mapstateToProps)
  (Header)
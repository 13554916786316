import React from 'react';
import { connect } from 'react-redux';
import { withAuth0 } from "@auth0/auth0-react";
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import HeaderAuth from '../../Molecules/Header/HeaderAuth';
import Footer from '../../Molecules/Footer/Footer';
import Button from '../../Atoms/Button/Button';
import API from '../../Api/ApiPath';
import strings from '../../../lang';
import Modal from '../../Molecules/Modal/Modal';
import { saveTenantUserId } from './AuthUserListActions';
import { getSSOHeaders } from '../../Api/ClientUtils';
import { userLogout } from '../../Pages/Logout/LogoutActions';
import LogoutMessage from '../../Molecules/Toaster/LogoutMessage';
class AuthUserList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            posts: [],
            sucMsg: '',
            errorMsg: '',
            tenantId: '',
            tenantName: '',
            pending: true,
            BearerToken: '',
            logouttoast: false,
        }        
        this.tenantUserIdHandler = this.tenantUserIdHandler.bind(this);
    }
    async componentDidMount() {
        try {
            const { getAccessTokenSilently } = this.props.auth0;
            const token = await getAccessTokenSilently();
            this.setState({ BearerToken: token })
        }
        catch (err) {
            this.setState({ errorMsg: 'Error - ' + JSON.stringify(err) })
            // window.setTimeout(()=>{this.setState({errorMsg:''})},5000)
            // if (err.errorCode === "consent_required" || err.errorCode === "interaction_required" || err.errorCode === "login_required") {
            this.setState({ logouttoast: true })
            this.logout()
            // }
        }
        // this.listTenants()
        this.listUsers()
    }
    listUsers() {
        API.get('list-tenant-user/' + this.props.savedtenantId, { headers: getSSOHeaders(this.state.BearerToken,this.props.userPrincipalName) })
            .then(response => {
                this.setState({ posts: response.data.tenantList })
                this.setState({ pending: false })
            })
            .catch(error => {
                this.setState({ errorMsg: 'Error - ' + error.response.data.message })
                // window.setTimeout(()=>{this.setState({errorMsg:''})},5000)
                this.setState({ pending: false })
            })
    }    
    deleteHandler() {
        const tenantUserId = this.state.DeleteID.toString()
        API.post('delete-tenant-user', { tenantUserId }, { headers: getSSOHeaders(this.state.BearerToken,this.props.userPrincipalName) })
            .then(response => {
                document.getElementById(`deleteModal`).style.display = 'none';
                this.setState({ sucMsg: 'Successfully deleted user' })
                // window.setTimeout(()=>{this.setState({sucMsg:''})},5000)
                this.listUsers()
            })
            .catch(error => {
                this.setState({ errorMsg: 'Error - ' + error.response.data.message })
                // window.setTimeout(()=>{this.setState({errorMsg:''})},5000)
            })

    }
    tenantUserIdHandler(tenantUserId) {
        const UserId = tenantUserId.toString()
        this.props.saveTenantUserId(UserId);
    }
    render() {
        const { posts, errorMsg, sucMsg, pending } = this.state
        const columns = [

            {
                name: 'User ID',
                selector: 'userEmail',
                sortable: true
            },
            {
                name: 'Status',
                selector: 'tenantUserStatus',
                sortable: true,
                width: '120px'
            },
            {
                name: 'User Role',
                selector: 'tenantUserRole',
                sortable: true,
                width: '120px'
            },
            {
                name: 'Last Login',
                selector: 'tenantUserLastLogin',
                sortable: true,
                width: '120px'
            },
            {
                name: 'Manage',
                selector: 'tenantUserId',
                // cell: row => <Link to={"/tenants-add-user/" + row.tenantUserId}><Button>Manage</Button></Link>,
                cell: row => <div>{row.tenantUserRole !== "ADMIN" && <Link onClick={(e) => {
                    e.stopPropagation();
                    this.tenantUserIdHandler(row.tenantUserId)
                }} to={"/user-add"}><Button>Manage</Button></Link>}</div>,
                ignoreRowClick: true,
                allowOverflow: true,
                width: '120px'
            },
            {
                name: 'Delete User',
                selector: 'tenantUserId',
                cell: (row) => <div
                    className="center"
                    onClick={e => {
                        e.stopPropagation();
                        document.getElementById(`deleteModal`).style.display = 'block';
                        this.setState({ DeleteID: row.tenantUserId })

                    }}
                    role="presentation"
                >
                    {row.tenantUserRole !== "ADMIN" && <img
                        src={require('../../../assets/images/delete.svg')}
                        alt={strings.delete}
                        className="delete-img"
                    />}
                </div>,
                ignoreRowClick: true,
                allowOverflow: true,
                width: '120px'
            }
        ];
        return (
            <div id="container">
                <HeaderAuth />
                <LogoutMessage logoutToast={this.state.logouttoast} />
                <Modal
                    headerText={strings.confirmDelete}
                    uniqueModalName="deleteModal"
                    message={strings.requestDeleteUserConfirmMessage}
                    minusText={strings.cancel}
                    minusAction={() => {
                        document.getElementById('deleteModal').style.display = 'none';
                    }}
                    plusText={strings.confirm}
                    plusAction={() => {
                        this.deleteHandler(this.state.DeleteID);
                    }}
                />
                <div className="container topmargin">
                    <div className="row">
                        <div className="col-md-10"><h4>Users</h4></div>
                        <div className="col-md-2"> <Link to={"user-add"} onClick={(e) => {
                    this.props.saveTenantUserId('')
                }} ><Button>New User</Button></Link></div>
                    </div>                    
                    {errorMsg !== '' && <div className="erroralert" >{errorMsg}</div>}
                    {sucMsg !== '' && <div className="successtext">{sucMsg}</div>}
                    <DataTable
                        columns={columns}
                        data={posts}
                        pagination={true}
                        highlightOnHover={true}
                        dense={true}
                        responsive={true}
                        progressPending={pending}
                        noHeader={true}
                    />
                </div>
                <Footer />
            </div>
        );
    }    
    async logout(props) {
        await this.sleep(5000);
        props.userLogout();
    }
    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
}
const mapstateToProps = state => {
    return {
        savedtenantId: state.userTenantId.savedtenantId, 
        userPrincipalName: state.userTenantId.saveIdpUsername       
    }
}
const mapDispatchToProps = dispatch => {
    return {
        saveTenantUserId: text => dispatch(saveTenantUserId(text)),
        userLogout: () => dispatch(userLogout())
    }
}
export default withAuth0(connect(
    mapstateToProps,
    mapDispatchToProps)
    (AuthUserList))


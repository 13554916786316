import React from 'react';
import { connect } from 'react-redux';
import HeaderAuth from '../../Molecules/Header/HeaderAuth';
import Footer from '../../Molecules/Footer/Footer';
import API from '../../Api/ApiPath';
import { getSSOHeaders } from '../../Api/ClientUtils';
import { userLogout } from '../../Pages/Logout/LogoutActions';
import LogoutMessage from '../../Molecules/Toaster/LogoutMessage';
import LoadingSpinner from '../../Molecules/LoadingSpinner/LoadingSpinner';
import Spinner from 'react-bootstrap/Spinner';
import { withAuth0 } from "@auth0/auth0-react";
class AuthAppAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tenantId: '',
            appId: '',
            tenantAppId: '',
            tenantApplId: '',
            applUrl: '',
            key: '',
            apiClientId: '',
            apiSecretId: '',
            applId: 'select',
            posts: [],
            applications: [],
            redirect: false,
            bearerToken: '',
            logouttoast: false,
            errorMsg: '',
            sucMsg: '',
            loading: true,
            connecting: false,
            scimEndPoint: '',
            clientIdLabel: '',
            secretIdLabel: '',
            applicationselected: false
        }
    }
    validate = () => {
        let isError = false;
        const errors = {
            AppNameError: "",
            applUrlError: "",
            apiClientIdError: "",
            apiSecretIdError: "",
            errorMsg: '',
            sucMsg: '',
        };

        if (this.state.applUrl === '') {
            isError = true;
            errors.applUrlError = "Application URL required";
        }
        // if (this.state.apiClientId === ''  || this.state.apiClientId === undefined) {
        //     isError = true;
        //     errors.apiClientIdError = "Client ID required";
        // }
        if (this.state.apiSecretId === '' || this.state.apiSecretId === undefined) {
            isError = true;
            errors.apiSecretIdError = "Field is required";
        }
        this.setState({
            ...this.state,
            ...errors
        });
        return isError;
    };
    changeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    ddchangeHandler(event) {
        this.setState({
            applId: event.target.value, apiClientId: '',
            apiSecretId: '',
        });
        this.applicationLabel(event.target.value);
    }
    applicationLabel(appID) {
        API.get('application-token-label/' + appID, { headers: getSSOHeaders(this.state.bearerToken, this.props.userPrincipalName) })
            .then(response => {
                this.setState({
                    clientIdLabel: response.data.tenantAppl.clientIdLabel,
                    secretIdLabel: response.data.tenantAppl.secretIdLabel,
                    applicationselected: true
                })
                if (response.data.tenantAppl.clientIdLabel === '' && response.data.tenantAppl.secretIdLabel === '') {
                    this.setState({ applicationselected: false })
                }
            })
            .catch(async error => {
                if (error.response.data.statusCode === 401) {
                    this.setState({ logouttoast: true })
                    await this.sleep(5000);
                    this.props.history.push("/logout");
                }
                // this.setState({ errorMsg: 'Error listing applications' })
            })
    }
    generateKeyHandler = (e) => {
        e.preventDefault()
        API.post('generate-token', {}, { headers: getSSOHeaders(this.state.bearerToken, this.props.userPrincipalName) })
            .then(response => {
                this.setState({ key: response.data.token })
                // window.setTimeout(() => { this.setState({ sucMsg: '' }) }, 5000)
            })
            .catch(async error => {
                if (error.response.data.statusCode === 401) {
                    this.setState({ logouttoast: true })
                    await this.sleep(5000);
                    this.props.history.push("/logout");
                }
                this.setState({ errorMsg: 'Error - ' + error.response.data.message })
                // window.setTimeout(() => { this.setState({ errorMsg: '' }) }, 5000)
            })
    }
    submitHandler = (e) => {
        e.preventDefault()
        const err = this.validate();
        if (!err) {
            this.setState({
                AppNameError: "",
                applUrlError: "",
                apiClientIdError: "",
                apiSecretIdError: ""
            });
            const tenantAppId = this.props.saveOperaAppId
            if (tenantAppId !== '' && tenantAppId !== undefined) {
                this.setState({ connecting: true }, () => {
                    API.post('update-tenant-application', this.state, { headers: getSSOHeaders(this.state.bearerToken, this.props.userPrincipalName) })
                        .then(response => {
                            this.setState({ sucMsg: 'Tenant Application updated successfully.', redirect: true, connecting: false });
                        })
                        .catch(error => {
                            this.setState({ errorMsg: 'Error - ' + error.response.data.message, connecting: false })
                        })
                });
            }
            // else {
            //     API.post('add-tenant-application', this.state)
            //         .then(response => {
            //             // this.props.history.push("/aad-opera-app-list/"+ this.state.tenantId);
            //             this.setState({ redirect: true });
            //         })
            //         .catch(error => {
            //             this.setState({ errorMsg: 'Error inserting data' })
            //         })
            // }
        }
    }
    async componentDidMount() {
        try {
            const { getAccessTokenSilently } = this.props.auth0;
            const token = await getAccessTokenSilently();
            const tenantId = this.props.savedtenantId
            const tenantAppId = this.props.saveOperaAppId            
            var scimEndPoint;
            if (process.env.REACT_APP_BASEURL === '' || process.env.REACT_APP_BASEURL === undefined) {
                scimEndPoint = window.location.protocol + '//' + window.location.hostname + '/serve/scim/v2';
            }
            else {
                var href = process.env.REACT_APP_BASEURL || 'http://localhost:8080/api/';
                var strArray = href.split(":");
                scimEndPoint = strArray[0] + ":" + strArray[1] + '/scim/v2';
            }
            this.setState({
                bearerToken: token,
                tenantId: tenantId,
                tenantAppId: tenantAppId,
                tenantApplId: tenantAppId,
                tenantAppCreatedBy: this.props.tenantAppCreatedBy,
                scimEndPoint: scimEndPoint
            })
            if (tenantAppId !== '' && tenantAppId !== undefined) {
                this.setState({ loading: true }, () => {
                    API.post('view-tenant-application', { tenantAppId }, { headers: getSSOHeaders(token, this.props.userPrincipalName) })
                        .then(response => {
                            const appId = response.data.tenantAppl.applId.toString()
                            this.applicationLabel(appId);
                            this.setState({
                                applId: appId,
                                appId: appId, applUrl: response.data.tenantAppl.applicationUrl,
                                apiClientId: response.data.tenantAppl.apiClientId,
                                apiSecretId: response.data.tenantAppl.apiSecretId,
                                key: response.data.tenantAppl.key,
                                loading: false,
                            })
                        })
                        .catch(error => {
                            this.setState({ errorMsg: 'Error fetching data', loading: false })
                            window.setTimeout(() => { this.setState({ errorMsg: '' }) }, 5000)
                        })
                });
            }
        }
        catch (err) {
            this.setState({ errorMsg: 'Error - ' + JSON.stringify(err) })
            // window.setTimeout(() => { this.setState({ errorMsg: '' }) }, 5000)
            // if (err.errorCode === "consent_required" || err.errorCode === "interaction_required" || err.errorCode === "login_required") {
            this.setState({ logouttoast: true })
            this.logout()
        }
        // } 
        this.listApps()
    }
    listApps() {
        API.get('list-application/', { headers: getSSOHeaders(this.state.bearerToken, this.props.userPrincipalName) })
            .then(response => {
                this.setState({ applications: response.data.tenantAppList })
            })
            .catch(error => {
                this.setState({ errorMsg: 'Error retreiving data' })
                window.setTimeout(() => { this.setState({ errorMsg: '' }) }, 5000)
            })
    }
    render() {
        const { sucMsg, errorMsg, loading, connecting } = this.state
        let pageTitle, buttonTitle,Readonlyflag;
        pageTitle = 'Manage Application';
        buttonTitle = 'Update';
        Readonlyflag = true;
        let tapplications = this.state.applications;
        let optionItems = tapplications.map((tapplication) =>
            <option key={tapplication.applId} value={tapplication.applId}>{tapplication.applName}</option>
        );
        return (
            <div id="container">
                <HeaderAuth />
                <LogoutMessage logoutToast={this.state.logouttoast} />
                <div className="container topmargin">
                    <h4>{pageTitle}</h4>
                    {errorMsg !== '' && <div className="erroralert" >{errorMsg}</div>}
                    {sucMsg !== '' && <div className="successtext">{sucMsg}</div>}
                    {loading ? <LoadingSpinner /> : <form>
                        <div className="row">
                            <div className="col-md-3">
                                <label>Application:</label>
                            </div>
                            <div className="col-md-6">
                                <label>
                                    <select id="applId" disabled={Readonlyflag} value={this.state.applId} onChange={this.ddchangeHandler.bind(this)}>
                                        <option value="select">Select</option>
                                        {optionItems}
                                    </select>
                                </label>
                                <div className="errortext">{this.state.AppNameError}</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">
                                <label>Application URL:</label>
                            </div>
                            <div className="col-md-6">
                                <input className="form-control" type="text" id="applUrl" name="applUrl" onChange={this.changeHandler} defaultValue={this.state.applUrl} />
                                <div className="errortext">{this.state.applUrlError}</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">
                                <label>SCIM Token:</label>
                            </div>
                            <div className="col-md-6">
                                <input readOnly className="form-control" type="text" id="key" name="key" onChange={this.changeHandler} value={this.state.key} />
                            </div>
                            <div className="col-md-3">
                                <button type='submit' onClick={this.generateKeyHandler} className="btn btn-primary">Generate Token</button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">
                                <label>URL for SCIM endpoint:</label>
                            </div>
                            <div className="col-md-6">
                                <label>{this.state.scimEndPoint}</label>
                            </div>
                        </div>
                        {this.state.applicationselected && <div className="row">
                            <div className="col-md-12">
                                <h5>PROVISIONING DETAILS</h5>
                            </div>
                        </div>}
                        {(this.state.applicationselected && this.state.clientIdLabel !== '' && this.state.clientIdLabel !== undefined) && <div className="row">
                            <div className="col-md-3">
                                <label>{this.state.clientIdLabel}</label>
                            </div>
                            <div className="col-md-6">
                                <input className="form-control" type="text" id="apiClientId" name="apiClientId" onChange={this.changeHandler} defaultValue={this.state.apiClientId} />
                                <div className="errortext">{this.state.apiClientIdError}</div>
                            </div>
                        </div>}
                        {this.state.applicationselected && <div className="row">
                            <div className="col-md-3">
                                <label>{this.state.secretIdLabel}</label>
                            </div>
                            <div className="col-md-6">
                                <input className="form-control" type="text" id="apiSecretId" name="apiSecretId" onChange={this.changeHandler} defaultValue={this.state.apiSecretId} />
                                <div className="errortext">{this.state.apiSecretIdError}</div>
                            </div>
                        </div>}
                        <div className="row">
                            <div className="col-md-3">
                            </div>
                            <div className="col-md-6">
                                {connecting === false && <button type='submit' onClick={this.submitHandler} className="btn btn-primary">{buttonTitle}</button>}
                                {connecting && <button className="btn btn-primary" variant="primary" disabled>
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />Connecting...</button>}
                            </div>
                        </div>
                    </form>}
                    {/* <Username username={username} /> */}
                </div>
                <Footer />
            </div>
        );
    }
    setErrorMessage(message, debug) {
        this.setState({
            error: { message: message, debug: debug }
        });
    }
    async logout(props) {
        await this.sleep(5000);
        props.userLogout();
    }
    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
}
// const Username = props => <div>{props.username}</div>;
const mapstateToProps = state => {
    return {
        tenantAppCreatedBy: state.userTenantId.saveIdpUsername,
        savedtenantId: state.userTenantId.savedtenantId,
        saveOperaAppId: state.AppId.saveOperaAppId,
        userPrincipalName: state.userTenantId.saveIdpUsername
    }
}
const mapDispatchToProps = dispatch => {
    return {
        userLogout: () => dispatch(userLogout())
    }
}
export default withAuth0(connect(
    mapstateToProps,
    mapDispatchToProps)
    (AuthAppAdd))
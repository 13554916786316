import {
    SAVE_TENANT_USER_ID
  } from '../../Constants/Type';
  
  const INITIAL_STATE = {
    saveTenantUserId: ''
  };
  const userId = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case SAVE_TENANT_USER_ID:
        return {
          ...state,
          saveTenantUserId: action.payload
        };              
        default:
          return state;
    }
  };
  
  export default userId;
  
import {
  USER_LOGOUT
} from '../../Constants/Type';

export const userLogout = () => {
  // console.log("Logout Action controller")
  return {
    type: USER_LOGOUT
  }

};

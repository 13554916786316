import { combineReducers } from 'redux';
import myLogin from '../components/Pages/Login/LoginReducer';
import myRoute from '../components/Router/ProtectedRouteReducer';
import userTenantId from '../components/SSOPages/Auth0/views/HomeReducer';
import userId from '../components/AuthPages/AuthUserList/AuthUserListReducer';
import AppId from '../components/AuthPages/AuthAppList/AuthAppListReducer';

const appReducer = combineReducers({
    myLogin,userTenantId,myRoute,userId,AppId  
});

export const rootReducer = (state, action) => {
    
    if (action.type === 'USER_LOGOUT') {
        sessionStorage.removeItem('persist:root')
        state = undefined
    }
    return appReducer(state, action)
}

import React from 'react';
import Footer from '../../Molecules/Footer/Footer';
import API from '../../Api/ApiPath';
import Header from '../../Molecules/Header/Header';
import LoadingSpinner from '../../Molecules/LoadingSpinner/LoadingSpinner';
class AdminPasswordReset extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            emailAddress: '',
            password: '',
            confirmPassword: '',
            sucMsg: '',
            errorMsg: '',
            loading: false,
            username: '',
            validateflag: false,
        }
    }
    changeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    submitHandler = (e) => {
        e.preventDefault()
        const err = this.validate();
        if (!err) {
            this.setState({
                usernameError: ""
            });
            API.post('save-password', this.state)
                .then(response => {
                    this.setState({
                        sucMsg: 'Password has been entered successfully.',
                        validateflag: true
                    })
                    window.setTimeout(() => { this.setState({ sucMsg: '' }) }, 5000)
                })
                .catch(error => {
                    this.setState({ errorMsg: 'Error - ' + error.response.data.message })
                    window.setTimeout(() => { this.setState({ errorMsg: '' }) }, 5000)
                })
        }
        this.setState({
            password: '',
            confirmPassword: ''
        });
    }
    componentDidMount() {
        const key = this.props.match.params.key
        if (key !== '' && key !== undefined) {
            this.setState({ loading: true }, () => {
                API.post('fetch-username', { key })
                    .then(response => {
                        this.setState({
                            username: response.data.loginUser.username,
                            loading: false
                        })
                    })
                    .catch(error => {
                        this.setState({ username: '', loading: false })
                    })
            });
        }
    }
    validate = () => {
        let isError = false;
        const errors = {
            passwordError: "",
            confirmPasswordError: "",
            errorMsg: '',
            sucMsg: '',
        };
        if (this.state.password === '') {
            isError = true;
            errors.passwordError = "Password is required";
        }
        if (this.state.confirmPassword === '') {
            isError = true;
            errors.confirmPasswordError = "Confirm Password is required";
        }
        if (this.state.confirmPassword !== this.state.password) {
            isError = true;
            errors.confirmPasswordError = "The passwords don't match";
        }
        this.setState({
            ...this.state,
            ...errors
        });
        return isError;
    };
    render() {
        const { errorMsg, sucMsg, loading } = this.state
        let pageTitle, buttonTitle
        pageTitle = 'Admin Password Reset';
        buttonTitle = 'Update';
        if (this.state.username === '')
            return (
                <div id="container">
                    <Header />
                    <div className="container topmargin">
                        <h6>Not a valid key !</h6>
                        
                    </div>
                    <Footer />
                </div>
            );
            if (this.state.validateflag === true)
            return (
                <div id="container">
                    <Header />
                    <div className="container topmargin">
                        <h6>Password has been entered successfully.</h6>
                        <h6>You can now login with your new password !</h6>
                    </div>
                    <Footer />
                </div>
            );    
        if (this.state.username !== '')
            return (
                <div id="container">
                    <Header />
                    <div className="container topmargin">
                        <h5>{pageTitle}</h5>
                        {errorMsg !== '' && <div className="erroralert" >{errorMsg}</div>}
                        {sucMsg !== '' && <div className="successtext">{sucMsg}</div>}
                        {loading ? <LoadingSpinner /> :
                            <form>
                                <div className="row">
                                    <div className="col-md-3">
                                        <label>Username:</label>
                                    </div>
                                    <div className="col-md-4">
                                        {this.state.username}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3">
                                        <label>Enter your password:</label>
                                    </div>
                                    <div className="col-md-4">
                                        <input className="form-control" type="password" id="password" name="password" onChange={this.changeHandler} value={this.state.password} />
                                        <div className="errortext">{this.state.passwordError}</div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3">
                                        <label>Confirm your password:</label>
                                    </div>
                                    <div className="col-md-4">
                                        <input className="form-control" type="password" id="confirmPassword" name="confirmPassword" onChange={this.changeHandler} value={this.state.confirmPassword} />
                                        <div className="errortext">{this.state.confirmPasswordError}</div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3">
                                    </div>
                                    <div className="col-md-9">
                                        <button type='submit' onClick={this.submitHandler} className="btn btn-primary">{buttonTitle}</button>
                                    </div>
                                </div>
                            </form>}

                    </div>
                    <Footer />
                </div>
            );
    }
}
export default AdminPasswordReset;